import React from "react";
import { makeStyles } from "@mui/styles";
import { S3_BUCKET_NAME } from "config";
import { useSelector } from "react-redux";
import { AftermarketProductVendorApiChannel, ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import NTP_LOGO from "../../../../../assets/img/aftermarket-vendor-logos/ntp-logo.png";
import GAIG_LOGO from "../../../../../assets/img/aftermarket-vendor-logos/gaig-logo.png";
import PREMIUM_2000_LOGO from "../../../../../assets/img/aftermarket-vendor-logos/premium-2000-logo.png";

export const ProductLogo = ({ product }: { product: ProposalProduct }) => {
  const classes = useStyles();

  const vendorProfile = useSelector((state: any) => state.vp);
  const { logo: vendorLogo } = vendorProfile;

  let logo = `https://${S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${vendorLogo}`;

  if (product.aftermarketProduct?.productLogoUrl) {
    logo = product.aftermarketProduct.productLogoUrl;
  }

  if (product.aftermarketProduct?.aftermarketVendorApiChannel === AftermarketProductVendorApiChannel.Ntp) {
    logo = NTP_LOGO;
    try {
      if (
        product.aftermarketProduct?.criteria[0].event.params.AFTERMARKETPRODUCTVENDORAPIDATA.APIRESPONSEDATA
          .groupCode === "P2K"
      ) {
        logo = PREMIUM_2000_LOGO;
      } else if (
        product.aftermarketProduct?.criteria[0].event.params.AFTERMARKET_PRODUCT_VENDOR_API_DATA.API_RESPONSE_DATA
          .groupCode === "P2K"
      ) {
        logo = PREMIUM_2000_LOGO;
      }
    } catch (e: any) {
      console.error(e);
    }
  } else if (product.aftermarketProduct?.aftermarketVendorApiChannel === AftermarketProductVendorApiChannel.Gaig) {
    logo = GAIG_LOGO;
  }

  return <img className={classes.productLogo} src={logo} alt="Product Logo" />;
};

const useStyles = makeStyles({
  productLogo: {
    width: "34px",
    height: "34px",
    marginRight: "8px",
  },
});
