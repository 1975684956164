import { useSelector } from "react-redux";
import { PortalConfigurationRolesSettingsKeys } from "types";
import { PortalConfigurationType, UserProfile } from "@trnsact/trnsact-shared-types";
import { portalConfigurationsSelectors } from "redux/portalConfigurationReducer";

export function usePortalConfigurationChecks() {
  const userProfile = useSelector<any, UserProfile>(state => state.userProfile);
  const portalConfigurationData = useSelector(portalConfigurationsSelectors.configData);

  const checkPortalConfigEntryForCurrentUserRole = (
    type: PortalConfigurationType,
    key: PortalConfigurationRolesSettingsKeys
  ) => {
    const portalConfiguration = portalConfigurationData?.[type];
    const rolesSettings = portalConfiguration?.jsonDefinition?.config.rolesSettings?.[key];

    return !!rolesSettings?.includes(userProfile.vendorContactRole);
  };

  return {
    checkPortalConfigEntryForCurrentUserRole,
  };
}
