import React from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { OpportunitiesNotifications } from "./OpportunitiesNotifications";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { portalConfigurationsSelectors } from "redux/portalConfigurationReducer";
import { PortalConfiguration, PortalConfigurationType } from "@trnsact/trnsact-shared-types";
import { usePortalConfigurationChecks } from "../../hooks";
import { PortalConfigurationRenderProps } from "../../types";
import { PortalConfigurationRolesSettingsKeys } from "../../../../types";
import { getPortalConfigurationDefaultConfig } from "../../lib/getPortalConfigurationDefaultConfig";

interface Props extends PortalConfigurationRenderProps {
  type: PortalConfigurationType;
}

export const NotificationsManagement = ({ type, ...props }: Props) => {
  const { checkPortalConfigEntryForCurrentUserRole } = usePortalConfigurationChecks();

  const isHidden = checkPortalConfigEntryForCurrentUserRole(
    PortalConfigurationType.PortalSetup,
    PortalConfigurationRolesSettingsKeys.NotificationsManagementHidden
  );

  const defaultConfig = getPortalConfigurationDefaultConfig(props.vendorProfileId, type);

  const portalConfig = useSelector<any, PortalConfiguration>(state =>
    portalConfigurationsSelectors.configDataByType(state, type)
  );

  if (isHidden) return null;

  return (
    <Grid item sm={12} md={6}>
      <CardContainer title="Notification Management" subTitle="Configure Email Alerts by Event Type">
        <OpportunitiesNotifications defaultConfig={defaultConfig} portalConfig={portalConfig} {...props} />
      </CardContainer>
    </Grid>
  );
};
