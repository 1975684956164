import React, { useMemo, useState } from "react";
import { Box } from "@mui/material";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { ProposalsViewTypes } from "../../types";
import { HubTable } from "../tables/hub/HubTable";
import { hubTableApiQueryByType } from "../../constants";
import { hubSearchFilter } from "../../lib/hubSearchFilters";
import { HubProposal, HubProposalResponse } from "../../api/types";
import { ProposalsTypeManager } from "../components/ProposalsTypeManager";
import { FiltersPanel } from "../../../../components/shared/FiltersPanel";
import { CardContainer } from "../../../../components/shared/CardContainer/CardContainer";
import { useAccessControlProposalHubTable } from "../../hooks/useAccessControlProposalHubTable";
import DashboardPage from "./DashboardPage";

const config = require("../../../../config");

export const ProposalsPage = () => {
  const classes = useStyles();

  const { hasAccessToAllProposals } = useAccessControlProposalHubTable();

  const dynamicsAccountId = useSelector((state: any) => state.account.dynamicsAccountId);

  const [mainSearchValue, setMainSearchValue] = useState<string>("");
  const [customTableKey, setCustomTableKey] = useState<number>(Date.now());
  const [proposalsType, setProposalsType] = useState<ProposalsViewTypes>(ProposalsViewTypes.My);

  const { data: proposalsHubData, loading: isProposalsLoading } = useQuery<HubProposalResponse>(
    hubTableApiQueryByType[proposalsType]
  );

  const handleGoToProposal = (proposal: HubProposal) => {
    window.open(`/proposalDetail/${proposal.id}?from=/proposals-hub`, "_blank");
  };

  const handleGoToProposalDetail = (proposal: HubProposal) => {
    const link = `https://app${
      config.environmentName === "dev" ? ".dev" : ""
    }.trnsact.com/ps/?vendorGUID=${dynamicsAccountId}&proposalId=${proposal.id}&viewOnly=true`;

    window.open(link, "_blank");
  };

  const filteredBySearchTableData = useMemo(
    () => hubSearchFilter(proposalsHubData?.proposals ?? [], mainSearchValue),
    [mainSearchValue, proposalsHubData?.proposals]
  );

  return (
    <CardContainer title="Proposals">
      <Box className={classes.container}>
        {hasAccessToAllProposals && (
          <ProposalsTypeManager currentType={proposalsType} onTypeChange={setProposalsType} />
        )}
        {proposalsType !== ProposalsViewTypes.Dashboard ? (
          <>
            <FiltersPanel
              withReset
              disabled={isProposalsLoading}
              onSearchChange={(filter, nextValue) => {
                setMainSearchValue(nextValue);
              }}
              searchInputProps={{
                placeholder: "Start searching proposals by customer...",
              }}
              extraCbForReset={() => {
                setCustomTableKey(Date.now());
              }}
            />

            <HubTable
              key={customTableKey}
              loading={isProposalsLoading}
              onGoToProposal={handleGoToProposal}
              tableData={filteredBySearchTableData}
              onGoToProposalDetail={handleGoToProposalDetail}
            />
          </>
        ) : (
          <DashboardPage />
        )}
      </Box>
    </CardContainer>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "1rem",
    display: "flex",
    flexDirection: "column",
  },
});
