import React, { useEffect, useState } from "react";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import InfoIcon from "@mui/icons-material/Info";
import { RecertificationFormValues, RecertificationOption } from "../../types";
import { iniitialRecertificationFormValues } from "../../constants";
import { ReactComponent as FilePDF } from "../../../../assets/svg/file-pdf.svg";
import { DropzoneDialog } from "mui-file-dropzone";
import { Task } from "@trnsact/trnsact-shared-types";
import { UploadedFileView } from "components/shared/UploadedFileView/UploadedFileView";
import { MultiSelect } from "components/form";
import { SelectOption } from "global";
import _ from "lodash";

interface RecertificationPortalConfiguration {
  isDocumentTurnedOn: boolean;
  isVerbalAuthorizationTurnedOn: boolean;
}

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  onClose: VoidFunction;
  creditAppOwners: any[];
  vendorOpportunityTasks: Task[];
  defaultFormValues: RecertificationFormValues;
  onSave: (formValue: RecertificationFormValues) => void;
  portalConfiguration: RecertificationPortalConfiguration;
}

export const RequestRecertificationModal = ({
  isOpen,
  onClose,
  onSave,
  isLoading,
  defaultFormValues,
  portalConfiguration,
  creditAppOwners = [],
}: Props) => {
  const classes = useStyles();

  const [recertificationFormValues, setRecertificationFormValues] = useState<RecertificationFormValues>(
    iniitialRecertificationFormValues
  );

  const [isUploadZoneOpen, setIsUploadZoneOpen] = useState<boolean>(false);

  const contacAlreadyHasSignatureTask = false;

  const contactOptions: SelectOption[] = creditAppOwners
    ?.filter(contact => !!contact.email)
    .map(contact => ({
      label: `${contact.firstName} ${contact.lastName} (${contact.email})`,
      value: contact.ownerPgId,
    }));

  const contactsByOwnerPgId = _.keyBy(creditAppOwners, "ownerPgId");

  useEffect(() => {
    if (isOpen) {
      setRecertificationFormValues(defaultFormValues || iniitialRecertificationFormValues);
    }
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    onSave(recertificationFormValues);
  };

  const handleUploadClick = () => {
    setIsUploadZoneOpen(true);
  };

  const isSaveButonDisabled =
    recertificationFormValues.recertificationOption === "send-task" &&
    recertificationFormValues.recertificationContactIds.length === 0;

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        Recertification
        <Divider style={{ marginTop: 10 }} />
      </DialogTitle>

      {!isLoading && (
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">Choose option</Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  className={classes.radioGroup}
                  value={recertificationFormValues.recertificationOption}
                  onChange={e =>
                    setRecertificationFormValues({
                      ...recertificationFormValues,
                      recertificationOption: e.target.value as RecertificationOption,
                    })
                  }>
                  <FormControlLabel
                    value="send-task"
                    control={<Radio color="primary" />}
                    label={<SendTaskOptionRadioLabel contacAlreadyHasSignatureTask={contacAlreadyHasSignatureTask} />}
                    disabled={contacAlreadyHasSignatureTask}
                  />

                  {portalConfiguration.isVerbalAuthorizationTurnedOn && (
                    <FormControlLabel
                      value="verbal-authorization"
                      control={<Radio color="primary" />}
                      label={<VerbalAuthorizationOptionRadioLabel />}
                    />
                  )}

                  {portalConfiguration.isDocumentTurnedOn && (
                    <FormControlLabel
                      value="document"
                      control={<Radio color="primary" />}
                      label={<DocumentOptionRadioLabel />}
                    />
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>

            {recertificationFormValues.recertificationOption === "send-task" && (
              <Grid item container style={{ marginTop: 10 }}>
                <Grid item xs={12}>
                  <MultiSelect
                    label="Choose Contacts"
                    value={recertificationFormValues.recertificationContactIds}
                    options={contactOptions ?? []}
                    onChange={selected => {
                      setRecertificationFormValues({
                        ...recertificationFormValues,
                        recertificationContactIds: [...selected.map(String)],
                      });
                    }}
                    renderValue={(selected: any) => (
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {selected.map((ownerPgId: string) => {
                          const concat: any = contactsByOwnerPgId[ownerPgId];
                          return (
                            <Chip
                              key={ownerPgId}
                              label={`${concat.firstName} ${concat.lastName}`}
                              style={{ margin: 2 }}
                            />
                          );
                        })}
                      </div>
                    )}
                  />
                </Grid>
              </Grid>
            )}

            {["document", "verbal-authorization"].includes(recertificationFormValues.recertificationOption) && (
              <>
                <Grid item xs={12}>
                  <TextField
                    label="Recertification Date"
                    type="datetime-local"
                    /* defaultValue="2017-05-24T10:30" */
                    value={recertificationFormValues.recertificationDate}
                    onChange={e => {
                      const dateString = e.target.value;
                      setRecertificationFormValues({
                        ...recertificationFormValues,
                        recertificationDate: dateString,
                      });
                    }}
                    required
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  {recertificationFormValues.file && (
                    <UploadedFileView
                      onDelete={() =>
                        setRecertificationFormValues({
                          ...recertificationFormValues,
                          file: null,
                        })
                      }
                      name={recertificationFormValues.file.name}
                    />
                  )}
                  {!recertificationFormValues.file && (
                    <>
                      <Button variant="contained" color="primary" size="small" onClick={handleUploadClick}>
                        Upload Credit Application
                      </Button>

                      <DropzoneDialog
                        fileObjects={[]}
                        open={isUploadZoneOpen}
                        onDrop={file => {
                          setRecertificationFormValues({
                            ...recertificationFormValues,
                            file: file[0],
                          });
                          setIsUploadZoneOpen(false);
                        }}
                        onSave={file => {
                          setRecertificationFormValues({
                            ...recertificationFormValues,
                            file: file[0],
                          });
                          setIsUploadZoneOpen(false);
                        }}
                        onClose={() => {
                          setIsUploadZoneOpen(false);
                        }}
                        filesLimit={1}
                        acceptedFiles={["application/pdf"]}
                        dropzoneText={"Drag or Click Here To Upload File"}
                        dialogTitle="Upload Credit Application"
                        showPreviews={false}
                        maxFileSize={5000000}
                      />
                    </>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
      )}

      <DialogActions>
        {!isLoading ? (
          <>
            <Button variant="outlined" color="primary" size="small" onClick={handleClose}>
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSave}
              disabled={isSaveButonDisabled}>
              Save
            </Button>
          </>
        ) : (
          <LinearProgress style={{ width: "100%" }} />
        )}
      </DialogActions>
    </Dialog>
  );
};

const DocumentOptionRadioLabel = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.optionRadioLabel}>
      <Grid item container alignItems="center" xs={12}>
        <FilePDF style={{ marginRight: 4, fill: "#616161", width: 24 }} />
        <Typography variant="subtitle2">Document</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">You've received an updated credit application and can upload it.</Typography>
      </Grid>
    </Grid>
  );
};

const VerbalAuthorizationOptionRadioLabel = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.optionRadioLabel}>
      <Grid item container alignItems="flex-end" xs={12}>
        <PhoneIcon className={classes.optionRadioLabelIcon} />
        <Typography variant="subtitle2">Verbal authorization</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          You have reviewed the necessary information with the Applicant and they have given you their verbal
          authorization to sign and recertify the application on their behalf.
        </Typography>
      </Grid>
    </Grid>
  );
};

const SendTaskOptionRadioLabel = ({ contacAlreadyHasSignatureTask }: { contacAlreadyHasSignatureTask: boolean }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.optionRadioLabel}>
      <Grid item container alignItems="flex-end" xs={12}>
        <EmailIcon className={classes.optionRadioLabelIcon} />
        <Typography variant="subtitle2">Digital</Typography>
        {contacAlreadyHasSignatureTask && (
          <>
            <InfoIcon className={classes.optionRadioInfoIcon} />
            <Typography variant="caption">The contact already has an active task</Typography>
          </>
        )}
      </Grid>
      <Grid item>
        <Typography variant="body1">
          Sends an email and creates a task for the applicant(s) to re-certify the credit application electronically.
        </Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  radioGroup: {
    "& .MuiFormControlLabel-root": {
      alignItems: "flex-start",
      "&:not(:last-child)": {
        marginBottom: 12,
      },
    },
    "& .MuiRadio-root": {
      paddingTop: 0,
    },
  },
  optionRadioLabel: {
    color: "black",
    "& h6": {
      textTransform: "none",
    },
  },
  optionRadioLabelIcon: {
    color: "#616161",
    marginRight: 4,
  },
  optionRadioInfoIcon: {
    color: "#616161",
    margin: "0 5px 0 15px",
    width: 18,
  },
});
