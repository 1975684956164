import { gql } from "@apollo/client";

export const creditApplicationOwnerFragment = gql`
  fragment creditApplicationOwnerAttributes on CreditApplicationOwner {
    ownerPgId
    pc
    pg
    pcOnly
    email
    creditApplicationId
    firstName
    middleName
    lastName
    nameSuffix
    ownerPercentage
    prevCounty
    ssn
    title
    dateOfBirth
    address
    address2
    city
    state
    postalCode
    driversLicenseNum
    dlStateIssued
    ownerNumber
    homeType
    homePhone
    phone
    timeAtAddressYears
    timeAtAddressMonths
    netWorth
    grossMonthlyIncome
    monthlyHousingPayment
    employerName
    employerAddress
    employerAddress2
    employerCity
    employerState
    employerZip
    employerEmail
    employmentStatus
    workPhone
    timeAtJobYears
    timeAtJobMonths
    mobilePhone
    signature
    signatureDate
    dlUploaded
    usCitizen
    commercialDriverMonths
    commercialDriverYears
    residentStatus
    providingGuaranty
    maritalStatus
    companyDriverYears
    ownerOperatorYears
    countryOfResidence
    ownershipType
    firstTimeFinancing
    nearestRelativeFirstName
    nearestRelativeLastName
    nearestRelativePhone
    nearestRelativeCity
    nearestRelativeZip
    prevAddress
    prevAddress2
    prevCity
    prevState
    prevCounty
    prevPostalCode
    reasonForDeclining
    yearsWithCdl
    personalGuaranteeSignature
    personalGuaranteeSignatureDate
    addendum
    ipAddress
    personalGuarantor {
      personalGuarantorId
      personalGuarantorDynamicsId
      personalGuarantorName
      mostRecentCbrs {
        bureauType
        creditBureauType
        creditReportConsumerId
        auditLog
        humanReadableReportDocumentId
        ownerPgId
        scoreType
        ficoScore
        fields {
          key
          value
        }
        publicRecordCount
        pastAmountDue
        totalInquiryCount
        satisfactoryAccountsCount
        installmentBalance
        monthlyPayment
        inquiryCountLastSixMonths
        derogAccountsCount
        realEstateBalance
        realEstatePayment
        tradelineCount
        pastDerogAccountsCount
        collectionRecordsCount
        revolvingAvailPercent
        paidAccountsCount
        oldestTradeline
        bankruptcyRecordCount
        revolvingBalance
        revolvingTotalCredit
        errorDescription
        createdDateTime
        modifiedDateTime
      }
    }
  }
`;
