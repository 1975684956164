import React from "react";
import { Nullable } from "global";
import { formatCurrency } from "utils";

interface Props {
  value: Nullable<number | string>;
  prefix?: React.ReactNode;
  formatter?: (value: Nullable<number | string>) => React.ReactNode;
}

export const ProductPrice = ({ formatter, prefix, value }: Props) => {
  const formattedValue = formatter ? formatter(value) : formatCurrency(value, true);

  return (
    <>
      {prefix}
      {value ? formattedValue : "$TBD"}
    </>
  );
};
