import React, { useMemo } from "react";
import _ from "lodash";
import { Rate } from "@trnsact/ntp-sdk";
import { formatNumberWithDelimiter } from "utils";
import { Addons } from "./Addons";
import { Fields } from "./Fields";
import { vendorsLogoByType } from "../../../../../constants";
import { productsCalculationManager } from "../../../../../lib";
import { FormattedLossCode } from "modules/desking/lib/lossCodesFormatter";
import { ProposalCardContainer } from "../cardContainer/ProposalCardContainer";
import { MarkupType, ProposalProductCardByVendorProps, ProposalProductCardModes } from "../../../../../types";
import PREMIUM_2000_LOGO from "../../../../../../../assets/img/aftermarket-vendor-logos/premium-2000-logo.png";

export const Card = ({
  mode,
  product,
  products,
  equipmentCost,
  prisingConfig,
  type = "simple",
  productConfiguration,
  updateProductConfiguration,
}: ProposalProductCardByVendorProps) => {
  const vendorApiData = useMemo<Rate>(() => {
    let data =
      product?.aftermarketProduct?.criteria?.[0]?.event?.params?.AFTERMARKET_PRODUCT_VENDOR_API_DATA?.API_RESPONSE_DATA;

    if (!data) {
      const productStoredInTrnsactFromApi = products.find(
        ({ proposalProductId }) => proposalProductId === product.proposalProductId
      );

      const productFromNtpApi = products.find(
        ({ aftermarketProduct }) => aftermarketProduct?.productName === product.aftermarketProduct?.productName
      );

      const productFromApi = productStoredInTrnsactFromApi || productFromNtpApi;

      if (productFromApi) {
        data =
          productFromApi.aftermarketProduct?.criteria?.[0]?.event.params.AFTERMARKET_PRODUCT_VENDOR_API_DATA
            ?.API_RESPONSE_DATA;
      }
    }

    return data;
  }, [product, products]);

  const addons =
    productConfiguration?.addons?.map((addon: any) => {
      const term = vendorApiData?.terms.find(
        ({ termMonths, termOdometer }) =>
          termMonths === productConfiguration?.coverage?.termMonths &&
          termOdometer === productConfiguration?.coverage?.termOdometer
      );

      const lossCode = term?.components
        .map(component => component.lossCodes)
        .flat()
        .find(lc => lc.code === addon.thirdPartyId);

      return { ...addon, cost: lossCode?.dealerCost ?? 0, retailCost: lossCode?.dealerCost ?? 0 };
    }) ?? [];

  const { deductibleOptions, mileageOptions, addonsOptions, logo } = useMemo(() => {
    const accessKey = `${productConfiguration?.coverage?.termMonths}-${productConfiguration?.coverage?.termOdometer}`;

    const addonsOptions = vendorApiData?.terms.reduce<Record<string, Rate["terms"][number]>>((acc, data) => {
      const key = `${data.termMonths}-${data.termOdometer}`;

      acc[key] = data;

      return acc;
    }, {})?.[accessKey];

    const term = vendorApiData?.terms.find(
      ({ termMonths, termOdometer }) =>
        termMonths === productConfiguration?.coverage?.termMonths &&
        termOdometer === productConfiguration?.coverage?.termOdometer
    );

    const lossCodes: Rate["terms"][number]["components"][number]["lossCodes"] = [];

    addonsOptions?.components.forEach(component => {
      component.lossCodes.forEach(lossCode => {
        lossCodes.push(lossCode);
      });
    });

    let cost = term ? term.dealerCost : 0;
    let retailCost = term ? term.dealerCost : 0;

    const needToUpdateProductConfiguration =
      mode !== ProposalProductCardModes.Constructor &&
      (productConfiguration.cost === 0 || productConfiguration.retailCost === 0);

    addons.forEach((addon: any) => {
      const lossCode = lossCodes.find(lc => lc.code === addon.thirdPartyId);

      if (lossCode) {
        cost += lossCode.dealerCost;
        retailCost += lossCode.suggestedRetailCost;
      }
    });

    if (needToUpdateProductConfiguration && cost !== 0 && retailCost !== 0) {
      updateProductConfiguration({
        cost,
        addons,
        coverage: term,
        retailCost: productsCalculationManager.calculateRetailCostWithMarkup(
          cost,
          retailCost,
          productConfiguration.markup.type,
          productConfiguration.markup.markup
        ),
      });
    }

    return {
      logo: vendorApiData?.groupCode !== "P2K" ? vendorsLogoByType["NTP"] : PREMIUM_2000_LOGO,
      deductibleOptions: _.uniqBy(vendorApiData?.terms, term => term.deductible.amount).map(
        term => term.deductible.amount
      ),
      mileageOptions:
        productConfiguration?.deductible || productConfiguration?.deductible === 0
          ? vendorApiData?.terms.filter(term => term.deductible.amount === productConfiguration?.deductible)
          : [],
      addonsOptions: addonsOptions,
      cost,
      retailCost,
    };
  }, [product, productConfiguration]);

  const handleDeductibleChange = (selectedAmount: number) => {
    updateProductConfiguration({
      coverage: null,
      deductible: selectedAmount,
    });
  };

  const formatCoverageTerm = (term: any): string => {
    return `${term.termMonths} Months / ${formatNumberWithDelimiter(term.termOdometer)} Miles`;
  };

  const handleMileageChange = (selectedTerm: any) => {
    let nextAddons = [...addons];

    const lossCodes = selectedTerm.components.map((component: any) => component.lossCodes).flat();

    nextAddons.forEach((addon: any, addonIndex) => {
      const lossCodeIndex = lossCodes.findIndex((lc: any) => addon.thirdPartyId === lc.code);

      if (lossCodeIndex !== -1) {
        nextAddons[addonIndex].cost = lossCodes[lossCodeIndex].dealerCost;
        nextAddons[addonIndex].retailCost = lossCodes[lossCodeIndex].dealerCost;
      }
    });

    const addonsCost = addons.reduce((acc: number, addon: any) => acc + addon.cost, 0);

    const markupToUse = productConfiguration?.markup;
    let retailCost = selectedTerm?.dealerCost ?? 0;

    if (markupToUse) {
      if (markupToUse.type === MarkupType.Percentage) {
        retailCost = _.round((selectedTerm?.dealerCost + addonsCost) * (1 + markupToUse.markup / 100), 2);
      } else {
        retailCost = selectedTerm?.dealerCost + addonsCost + markupToUse.markup;
      }
    }

    updateProductConfiguration({
      coverage: selectedTerm,
      retailCost,
      cost: (selectedTerm?.dealerCost ?? 0) + addonsCost,
      markup: markupToUse ? markupToUse : { type: MarkupType.Percentage, markup: 0 },
      COVERAGE_TERM: formatCoverageTerm(selectedTerm),
      addons: nextAddons,
    });
  };

  const handleCheckboxChange = (formattedLossCodes: FormattedLossCode[]) => {
    if (!productConfiguration?.coverage) return;

    let nextAddons = [...addons];

    formattedLossCodes.forEach(lossCode => {
      const lossCodeIndex = nextAddons.findIndex((addon: any) => addon.thirdPartyId === lossCode.thirdPartyId);

      if (lossCodeIndex === -1 && lossCode.value) {
        nextAddons = [...nextAddons, lossCode];
      } else {
        nextAddons = nextAddons.filter(
          (addon: any) =>
            addon.thirdPartyId !== lossCode.thirdPartyId ||
            (addon.thirdPartyId !== lossCode.thirdPartyId && !lossCode.value)
        );
      }
    });

    const addonsCost = nextAddons.reduce((acc: number, addon: any) => acc + addon.cost, 0);

    const newCost = (productConfiguration?.coverage.dealerCost ?? 0) + addonsCost;

    let newRetailCost = newCost;

    const markupToUse = productConfiguration?.markup;

    if (markupToUse) {
      if (markupToUse.type === MarkupType.Percentage) {
        newRetailCost = _.round(newRetailCost * (1 + markupToUse.markup / 100), 2);
      } else {
        newRetailCost = newRetailCost + markupToUse.markup;
      }
    }

    updateProductConfiguration({
      cost: newCost,
      markup: markupToUse,
      retailCost: newRetailCost,
      addons: nextAddons,
    });
  };

  return (
    <ProposalCardContainer
      mode={mode}
      type={type}
      logo={logo}
      factsSkipped={[]}
      proposalProduct={product}
      prisingConfig={prisingConfig}
      equipmentCost={equipmentCost}
      productConfiguration={productConfiguration}
      updateProductConfiguration={updateProductConfiguration}>
      <Fields
        disableAllFields={false}
        uniqueDeductibles={deductibleOptions}
        filteredMileageOptions={mileageOptions}
        handleMileageChange={handleMileageChange}
        configuration={productConfiguration}
        handleDeductibleChange={handleDeductibleChange}
        formatCoverageTerm={formatCoverageTerm}
      />

      {addonsOptions && (
        <Addons addons={addons} addonsOptions={addonsOptions} handleCheckboxChange={handleCheckboxChange} />
      )}
    </ProposalCardContainer>
  );
};
