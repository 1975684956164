import { PartnerProfilePartnerLinks, ProposalMenu, ProposalMenuOption } from "@trnsact/trnsact-shared-types";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/src/generated";
import { LayoutSections, MenuOptionToAddEdit, MenuTemplateGeneralFormInputs } from "../types";
import { Section } from "./types";

const setMenu = (payload: ProposalMenu) =>
  ({
    type: "MENU_CONSTRUCTOR_SET_PROPOSALS_MENU",
    payload,
  }) as const;

const setMenuGeneralFormValues = (payload: MenuTemplateGeneralFormInputs) =>
  ({
    type: "MENU_CONSTRUCTOR_SET_MENU_GENERAL_FORM_VALUES",
    payload,
  }) as const;

const setProducts = (payload: ProposalProduct[]) =>
  ({
    type: "MENU_CONSTRUCTOR_SET_PROPOSALS_PRODUCTS",
    payload,
  }) as const;

const setSelectedProducts = (payload: ProposalProduct[]) =>
  ({
    type: "MENU_CONSTRUCTOR_SET_SELECTED_PROPOSALS_PRODUCTS",
    payload,
  }) as const;

const unselectProduct = (payload: { productId: string }) =>
  ({
    type: "MENU_CONSTRUCTOR_UNSELECT_PRODUCT",
    payload,
  }) as const;

const setMenuOptionToAddEdit = (payload: { currentMenuOption: MenuOptionToAddEdit }) =>
  ({
    type: "MENU_CONSTRUCTOR_SET_MENU_OPTION_TO_ADD_EDIT",
    payload,
  }) as const;

const setPartnerLinksById = (payload: Record<string, PartnerProfilePartnerLinks>) =>
  ({
    type: "MENU_CONSTRUCTOR_SET_PARTNER_LINKS_BY_ID",
    payload,
  }) as const;

const addMenuOption = (payload: { menuOption: MenuOptionToAddEdit }) =>
  ({
    type: "MENU_CONSTRUCTOR_ADD_MENU_OPTION",
    payload,
  }) as const;

const editMenuOption = (payload: { menuOption: MenuOptionToAddEdit }) =>
  ({
    type: "MENU_CONSTRUCTOR_EDIT_MENU_OPTION",
    payload,
  }) as const;

const removeMenuOption = (payload: { currentMenuOption: MenuOptionToAddEdit }) =>
  ({
    type: "MENU_CONSTRUCTOR_REMOVE_MENU_OPTION",
    payload,
  }) as const;

const selectMenuOption = (payload: { menuOption: ProposalMenuOption | null }) =>
  ({
    type: "MENU_CONSTRUCTOR_SELECT_MENU_OPTION",
    payload,
  }) as const;

const addMenuOptionProduct = (payload: { menuType: string; product: ProposalProduct }) =>
  ({
    type: "MENU_CONSTRUCTOR_ADD_MENU_OPTION_PRODUCT",
    payload,
  }) as const;

const removeMenuOptionProduct = (payload: { menuType: string; productId: string }) =>
  ({
    type: "MENU_CONSTRUCTOR_REMOVE_MENU_OPTION_PRODUCT",
    payload,
  }) as const;

const updateProductConfiguration = (payload: { productId: string; data: Record<string, any> }) =>
  ({
    type: "MENU_CONSTRUCTOR_UPDATE_PRODUCT_CONFIGURATION",
    payload,
  }) as const;

const updateProductConfigurationInMenu = (payload: {
  productId: string;
  menuName: string;
  data: Record<string, any>;
}) =>
  ({
    type: "MENU_CONSTRUCTOR_UPDATE_PRODUCT_CONFIGURATION_IN_MENU",
    payload,
  }) as const;

const setOrderOfProduct = (payload: { menuName: string; from: number; to: number }) =>
  ({
    type: "MENU_CONSTRUCTOR_SWITCH_PRODUCTS",
    payload,
  }) as const;

const addAllProductsToMenu = () =>
  ({
    type: "MENU_CONSTRUCTOR_ADD_ALL_PRODUCTS_TO_MENU",
  }) as const;

const updateSectionLayout = (payload: { section: LayoutSections; key: keyof Section; value: boolean }) =>
  ({
    type: "MENU_CONSTRUCTOR_UPDATE_SECTION_LAYOUT",
    payload,
  }) as const;

const setInitialMenu = (payload: ProposalMenu) =>
  ({
    type: "MENU_CONSTRUCTOR_SET_INITIAL_PROPOSALS_MENU",
    payload,
  }) as const;

const setIsLoading = (payload: boolean) =>
  ({
    type: "MENU_CONSTRUCTOR_SET_IS_LOADING",
    payload,
  }) as const;

const clearState = () =>
  ({
    type: "MENU_CONSTRUCTOR_CLEAR",
  }) as const;

export const menuConstructorActions = {
  setMenu,
  setProducts,
  setSelectedProducts,
  setMenuOptionToAddEdit,
  setMenuGeneralFormValues,
  setPartnerLinksById,
  addMenuOption,
  editMenuOption,
  removeMenuOption,
  selectMenuOption,
  addMenuOptionProduct,
  removeMenuOptionProduct,
  updateProductConfiguration,
  updateProductConfigurationInMenu,
  setInitialMenu,
  setIsLoading,
  clearState,
  setOrderOfProduct,
  addAllProductsToMenu,
  updateSectionLayout,
  unselectProduct,
};
