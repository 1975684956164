import React from "react";
import { useSelector } from "react-redux";
import { Info } from "@mui/icons-material";
import { keyframes, Stack, Typography } from "@mui/material";
import { deskingSelectors } from "../../../../../model";
import { MenuCards, MenuFields, MenuSelector } from "../../../../components";

export const Menu = () => {
  const products = useSelector(deskingSelectors.proposalsProducts);

  if (!products.length) {
    const pulse = keyframes`
        0% { transform: scale(1); }
        50% { transform: scale(1.1); }
        100% { transform: scale(1); }
    `;

    return (
      <Stack gap="1rem" alignItems="center">
        <Info sx={{ animation: `${pulse} 2s ease-in-out infinite`, fontSize: "4rem" }} color="info" />

        <Stack>
          <Typography align="center" variant="subtitle1" component="span">
            No qualifying products available for the selected equipment.
          </Typography>

          <Typography align="center" variant="body1" component="span">
            Try selecting different equipment or contact your administrator to confirm product availability.
          </Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <>
      <MenuSelector />
      <MenuFields />
      <MenuCards />
    </>
  );
};
