import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { useLazyQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import DatePicker from "react-datepicker";
import Paper from "@mui/material/Paper";
import "react-datepicker/dist/react-datepicker.css";
import {
  addDays,
  addMonths,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import calendar from "assets/img/icons/calendar.png";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";

import Box from "@mui/material/Box";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ButtonGroup from "@mui/material/ButtonGroup";

import LocationsTreeViewDropdown from "../../../../components/LocationsTreeViewDropdown/LocationsTreeViewDropdown";
import ChipInput from "../../../../components/Generic/ChipInput/ChipInput";
import { buildTree, getDropdownTreeSelectDefaultOptions } from "../../../../services/locationsService";
import _ from "lodash";
import { ChartContainer } from "@mui/x-charts/ChartContainer";
import { BarChart, LineChart } from "@mui/x-charts";

import { GET_PROPOSAL_METRICS } from "modules/proposals/api";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps, accordionSummaryClasses } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import { vendorContactRoleHumanReadable } from "../../../../utils";

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&::before": {
      display: "none",
    },
  })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]: {
    transform: "rotate(90deg)",
  },
  [`& .${accordionSummaryClasses.content}`]: {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles("dark", {
    backgroundColor: "rgba(255, 255, 255, .05)",
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Q_LOCATIONS = gql`
  query locations {
    locations {
      locationId
      locationName
      category
      accountDynamicsId
      ocaContactId
      ocaContact {
        fullName
      }
      contactIds
      locationParentId
      notificationAppReceipt
      childrenLocation {
        locationId
        locationName
      }
      locationParentName
    }
  }
`;

const useStyles = makeStyles({
  datePicker: {
    fontSize: "14px",
    width: "120px",
    textAlign: "right",
    padding: "5px",
    border: "1px solid #CCC",
    borderRadius: "5px",
    backgroundImage: `url(${calendar})`,
    backgroundPosition: "left",
    backgroundRepeat: "no-repeat",
  },
  calendarContainer: {
    marginBottom: "20px",
    borderBottom: "1px solid #ccc1c1",
    padding: "10px",
  },
  salesRepContainer: {
    marginTop: "30px",
  },
  noResults: {
    fontSize: "1rem",
    marginTop: "15px",
  },
  separator: {
    borderTop: "1px solid #a2adb3",
  },
  categoryHeader: {
    fontVariant: "small-caps",
    fontSize: "15px",
    color: "black",
  },
  indentItem: {
    paddingLeft: "2rem",
    fontSize: "14px",
  },
  left: {
    textAlign: "left",
  },
  header1stElement: {
    textAlign: "center",
    textShadow: "1px 1px 4px #CCC",
    fontSize: "1rem !important",
  },
  headerCentered: {
    textAlign: "center",
    textShadow: "1px 1px 4px #CCC",
    fontWeight: "bold",
  },
  cellsCentered: {
    textAlign: "center",
  },
  cellsCenteredFixedWidth: {
    textAlign: "center",
    minWidth: "120px",
  },
});

const Dashboard = () => {
  const classes = useStyles();
  const account = useSelector((state: any) => state.account);

  const [yesterday, setYesterday] = useState(new Date().setDate(new Date().getDate() - 1));
  const [today, setToday] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date().setFullYear(new Date().getFullYear() - 1));
  const [endDate, setEndDate] = useState(new Date());
  const [filterByUserProfileId, setFilterByUserProfileId] = useState("");

  const [locationsTree, setLocationsTree] = useState<any[]>();
  const [proposalByUser, setProposalByUser] = useState<any[]>([]);
  const [proposalByLocation, setProposalByLocation] = useState<any[]>([]);
  const [dailyVolume, setDailyVolume] = useState<any[]>([]);
  const [weeklyVolume, setWeeklyVolume] = useState<any[]>([]);
  const [monthlyVolume, setMonthlyVolume] = useState<any[]>([]);
  const [proposalSummary, setProposalSummary] = useState<any[]>([]);
  const [locationChartLabels, setLocationChartLabels] = useState([]);
  const [locId, setLocId] = useState(null);
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [getLocations, { data: locationsData, loading: loadingLocations }] = useLazyQuery(Q_LOCATIONS, {
    context: { authRequired: true },
  });

  const [getMetrics, { data: metricsData, loading: loadingMetrics }] = useLazyQuery(GET_PROPOSAL_METRICS, {
    context: { authRequired: true },
    variables: {
      input: {
        accountId: account.accountId,
        userProfileId: _.get(filterByUserProfileId, "id"),
        from: format(startDate, "yyyy-MM-dd"),
        to: format(endDate, "yyyy-MM-dd"),
        locationId: locId === null ? undefined : locId,
      },
    },
  });

  useEffect(() => {
    if (startDate && endDate && account) {
      if (!locationsData && !loadingLocations) {
        getLocations();
      }
      if (!loadingMetrics && _.isEmpty(metricsData)) {
        getMetrics();
      }
    }
  }, [account, startDate, endDate, loadingMetrics, metricsData]);

  useEffect(() => {
    if (metricsData && metricsData.proposalMetrics && !loadingMetrics) {
      setProposalByUser(metricsData.proposalMetrics.created.byUser);
      setProposalByLocation(metricsData.proposalMetrics.accepted.markup.byLocation);
      setDailyVolume(metricsData.proposalMetrics.created.byDate);
      setWeeklyVolume(metricsData.proposalMetrics.created.byWeek);
      setMonthlyVolume(metricsData.proposalMetrics.created.byMonth);
      // We’ll build a mergedUsers object to gather counts and markups by user
      const mergedUsers: any = {};

      // Helper to make sure each user is initialized in mergedUsers
      const initUser = (fullName: string) => {
        if (!mergedUsers[fullName]) {
          mergedUsers[fullName] = {
            fullName,
            proposalsCreated: 0,
            proposalsSent: 0,
            proposalsAccepted: 0,
            markupCreated: 0,
            markupSent: 0,
            markupAccepted: 0,
          };
        }
      };

      // A small helper to safely read arrays (in case some part is missing)
      const getArray = (obj: any, path: any) => {
        // e.g. getArray(proposalMetrics, 'created.byUser') => returns array or []
        return path.split(".").reduce((acc: any, cur: any) => (acc && acc[cur]) || [], obj);
      };

      // Merge data for proposals "created", "sent", "accepted"
      // 1. Merge counts (byUser)
      // 2. Merge markup (markup.byUser)
      ["created", "sent", "accepted"].forEach(metric => {
        // 1) Merge the byUser totals
        getArray(metricsData.proposalMetrics, `${metric}.byUser`).forEach((item: any) => {
          initUser(item.fullName);
          if (metric === "created") mergedUsers[item.fullName].proposalsCreated = item.total;
          if (metric === "sent") mergedUsers[item.fullName].proposalsSent = item.total;
          if (metric === "accepted") mergedUsers[item.fullName].proposalsAccepted = item.total;
        });

        // 2) Merge the markup byUser
        getArray(metricsData.proposalMetrics, `${metric}.markup.byUser`).forEach((item: any) => {
          initUser(item.fullName);
          if (metric === "created") mergedUsers[item.fullName].markupCreated = item.markup || 0;
          if (metric === "sent") mergedUsers[item.fullName].markupSent = item.markup || 0;
          if (metric === "accepted") mergedUsers[item.fullName].markupAccepted = item.markup || 0;
        });
      });

      // Convert mergedUsers to an array
      setProposalSummary(Object.values(mergedUsers));
      setIsLoading(false);
    }
  }, [metricsData, loadingMetrics]);

  const treeSelectOptions = getDropdownTreeSelectDefaultOptions({
    onChange: (selectedNode: any) => setLocId(selectedNode.value),
    texts: { placeholder: "Choose Location" },
  });
  const simpleHash = (input: string) => {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
      const char = input.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash;
    }
    return hash;
  };

  const stringToColorHex = (inputString: string) => {
    const hash = simpleHash(inputString);

    const hex = Math.abs(hash).toString(16);

    return "#" + hex.padEnd(6, "0").substring(0, 6);
  };

  useEffect(() => {
    if (locationsData) {
      const options = _.map(locationsData.locations, location => {
        return {
          ...location,
          checked: location.locationId == locId,
        };
      });
      setLocationChartLabels(
        locationsData.locations.map(l => {
          return {
            ...l,
            hexColor: stringToColorHex(l.locationId),
          };
        })
      );
      options.push({ locationId: null, locationName: "Unassigned", checked: locId == null });

      setLocationsTree(buildTree(options));
    }
  }, [locationsData, locId]);

  const [tabSelected, setTabSelect] = useState("1");

  const handleTabChange = (event: any, newValue: any) => {
    setTabSelect(newValue);
    setLocId(null);
  };
  const quickDateActions = (key: string) => {
    const optionList = [
      { lbl: "Today", id: "today" },
      { lbl: "Yesterday", id: "yesterday" },
      { lbl: "This Week", id: "this_week" },
      { lbl: "Last Week", id: "last_week" },
      { lbl: "This Month", id: "this_month" },
      { lbl: "Last Month", id: "last_month" },
      { lbl: "This Quarter", id: "this_quarter" },
      { lbl: "Last Quarter", id: "last_quarter" },
      { lbl: "This Year", id: "this_year" },
      { lbl: "Last Year", id: "last_year" },
    ];
    return (
      <Box
        key={key}
        sx={{
          height: "100%",
          display: "flex",
          "& > *": {
            m: 1,
          },
        }}>
        <ButtonGroup orientation="vertical" variant="contained" style={{ position: "absolute", minWidth: "25vh" }}>
          {optionList.map(opt => (
            <Button size="small" id={opt.id} key={opt.id} onClick={handleSetCalendar}>
              {opt.lbl}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
    );
  };
  const handleFilterByUserProfileId = (newValue: any) => {
    setFilterByUserProfileId(newValue);
  };
  const handleSetCalendar = e => {
    e.preventDefault();
    const range = e.currentTarget.id;
    let startDate, endDate;

    switch (range) {
      case "today":
        startDate = new Date();
        endDate = new Date();
        break;
      case "yesterday":
        startDate = addDays(new Date(), -1);
        endDate = addDays(new Date(), -1);
        break;
      case "this_week":
        startDate = startOfWeek(new Date());
        endDate = endOfWeek(new Date());
        break;
      case "last_week":
        startDate = startOfWeek(addDays(new Date(), -7));
        endDate = endOfWeek(addDays(new Date(), -7));
        break;
      case "this_month":
        startDate = startOfMonth(new Date());
        endDate = endOfMonth(new Date());
        break;
      case "last_month":
        startDate = startOfMonth(addDays(new Date(), -1 * new Date().getDate()));
        endDate = endOfMonth(addDays(new Date(), -1 * new Date().getDate()));
        break;
      case "this_quarter":
        startDate = startOfQuarter(new Date());
        endDate = endOfQuarter(new Date());
        break;
      case "last_quarter":
        startDate = startOfQuarter(addMonths(new Date(), -3));
        endDate = endOfQuarter(addMonths(new Date(), -3));
        break;
      case "this_year":
        startDate = startOfYear(new Date());
        endDate = endOfYear(new Date());
        break;
      case "last_year":
        startDate = startOfYear(addDays(new Date(), -365));
        endDate = endOfYear(addDays(new Date(), -365));
        break;
    }

    setStartDate(startDate);
    setEndDate(endDate);
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} sm={12} xs={12} md={12}>
        <Typography variant="h6">Filters:</Typography>
      </Grid>
      <Grid container className={classes.calendarContainer} spacing={2}>
        <Grid item lg={4} sm={6} xs={6} md={6}>
          <Grid container>
            <Grid item lg={6} sm={6} xs={6} md={6}>
              <span>From: </span>
              <DatePicker
                selected={startDate}
                className={classes.datePicker}
                onChange={date => setStartDate(date)}
                selectsStart
                dateFormat="MM-dd-yyyy"
                startDate={startDate}
                endDate={endDate}
                maxDate={yesterday}>
                {quickDateActions("startDateCal")}
              </DatePicker>
            </Grid>
            <Grid item lg={6} sm={6} xs={6} md={6}>
              <span>To: </span>
              <DatePicker
                className={classes.datePicker}
                selected={endDate}
                dateFormat="MM-dd-yyyy"
                onChange={date => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                maxDate={today}>
                {quickDateActions("endDateCal")}
              </DatePicker>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={4} xs={12} md={8} sm={12}>
          <FormControl fullWidth>
            <LocationsTreeViewDropdown tree={locationsTree} treeSelectOptions={treeSelectOptions} />
          </FormControl>
        </Grid>

        <Grid item lg={4} xs={12} md={8} sm={12}>
          <Autocomplete
            value={filterByUserProfileId}
            options={account.contacts || []}
            onChange={(event, newValue) => {
              handleFilterByUserProfileId(newValue);
            }}
            getOptionLabel={(option: any) => {
              return option.fullName || "";
            }}
            renderInput={params => <TextField {...params} variant="standard" label="User" placeholder="" />}
          />
        </Grid>
      </Grid>
      <Grid item lg={12} sm={12} xs={12} md={12}>
        <TabContext value={tabSelected}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange} aria-label="Reporting" variant="fullWidth">
              <Tab label="Summary" value="1" />
              <Tab label="Proposals by User" value="2" />
              <Tab label="Total Markup by Location" value="3" />
              <Tab label="Proposal Volume Over Time" value="4" />
            </TabList>
          </Box>

          {/* 1) SUMMARY */}
          <TabPanel value="1">
            {loadingMetrics ? (
              <LinearProgress color="success" />
            ) : (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>User</strong>
                      </TableCell>
                      <TableCell align="right">
                        <strong>Proposals Created</strong>
                      </TableCell>
                      <TableCell align="right">
                        <strong>Proposals Sent</strong>
                      </TableCell>
                      <TableCell align="right">
                        <strong>Proposals Accepted</strong>
                      </TableCell>
                      <TableCell align="right">
                        <strong>Markup on Created ($)</strong>
                      </TableCell>
                      <TableCell align="right">
                        <strong>Markup on Sent ($)</strong>
                      </TableCell>
                      <TableCell align="right">
                        <strong>Markup on Accepted ($)</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {proposalSummary.map(user => (
                      <TableRow key={user.fullName}>
                        <TableCell component="th" scope="row">
                          {user.fullName}
                        </TableCell>
                        <TableCell align="right">{user.proposalsCreated}</TableCell>
                        <TableCell align="right">{user.proposalsSent}</TableCell>
                        <TableCell align="right">{user.proposalsAccepted}</TableCell>
                        <TableCell align="right">{user.markupCreated}</TableCell>
                        <TableCell align="right">{user.markupSent}</TableCell>
                        <TableCell align="right">{user.markupAccepted}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </TabPanel>
          {/* 2) BY SALES REPRESENTATIVE */}
          <TabPanel value="2">
            {loadingMetrics ? (
              <LinearProgress color="success" />
            ) : (
              <BarChart
                dataset={proposalByUser}
                height={500}
                series={[{ label: "No. Proposals", dataKey: "total" }]}
                xAxis={[{ scaleType: "band", dataKey: "fullName", label: "Users" }]}
              />
            )}
          </TabPanel>

          {/* 3) BY LOCATION */}
          <TabPanel value="3">
            {loadingMetrics && <LinearProgress color="success" />}
            {proposalByLocation && (
              <BarChart
                dataset={proposalByLocation}
                height={500}
                series={[{ label: "Markup", dataKey: "markup" }]}
                xAxis={[{ scaleType: "band", dataKey: "locationName", label: "Location" }]}
              />
            )}
          </TabPanel>
          {/* 3) VOLUME OVER TIME */}
          <TabPanel value="4">
            {loadingMetrics ? (
              <LinearProgress color="success" />
            ) : (
              <>
                <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography component="span">Daily</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {dailyVolume && (
                      <LineChart
                        dataset={dailyVolume}
                        height={500}
                        series={[{ label: "Total", dataKey: "total" }]}
                        xAxis={[
                          {
                            scaleType: "point",
                            dataKey: "period",
                            label: "Date",
                            valueFormatter: v => format(new Date(v), "yyyy-MM-dd"),
                          },
                        ]}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
                  <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography component="span">Weekly</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {weeklyVolume && (
                      <LineChart
                        dataset={weeklyVolume}
                        height={500}
                        series={[{ label: "Total", dataKey: "total" }]}
                        xAxis={[
                          {
                            scaleType: "point",
                            dataKey: "period",
                            label: "Date",
                            valueFormatter: v => format(new Date(v), "yyyy-MM-dd"),
                          },
                        ]}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
                  <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography component="span">Monthly</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {monthlyVolume && (
                      <LineChart
                        dataset={monthlyVolume}
                        height={500}
                        series={[{ label: "Total", dataKey: "total" }]}
                        xAxis={[
                          {
                            scaleType: "point",
                            dataKey: "period",
                            label: "Date",
                            valueFormatter: v => format(new Date(v), "yyyy-MM-dd"),
                          },
                        ]}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
