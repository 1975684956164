import React, { useEffect } from "react";
import Delta from "quill-delta";
import { AftermarketProductQualifiedEventOptions } from "@trnsact/business-criteria/dist/src/models/rule-event/aftermarket/product-qualified/aftermarket-product-qualified-event-options";
import { DynamicFields } from "./DynamicFields";
import { PricingResults } from "./PricingResults";
import { allFacts } from "../../../../../constants";
import { useRulesEngine } from "../../../../../context";
import { logError } from "../../../../../../../utils/logger";
import { productsCalculationManager } from "../../../../../lib";
import { ProposalCardContainer } from "../cardContainer/ProposalCardContainer";
import { MarkupType, ProposalProductCardByVendorProps, ProposalProductCardModes } from "../../../../../types";

export const Card = ({
  mode,
  product,
  equipmentCost,
  prisingConfig,
  type = "simple",
  factsToCheck = [],
  factsSkipped = [],
  hideFieldsForRender,
  productConfiguration,
  isNeedRunJsonEngine = 0,
  updateProductConfiguration,
}: ProposalProductCardByVendorProps) => {
  const { engine, handleAddProductsRules } = useRulesEngine();

  Object.values(allFacts).forEach(factKey => {
    const factFromConfig = productConfiguration[factKey];

    if (!factFromConfig) return;
    engine.addFact(factKey, factFromConfig);
  }, {});

  useEffect(() => {
    handleAddProductsRules(product);
  }, [product]);

  const createProductCoverageDelta = (coverageDetails: any, coverageTerm: string) => {
    try {
      const coverageDelta = new Delta();
      if (coverageDetails?.ops) {
        coverageDetails.ops.forEach((op: any) => {
          if (op.insert) {
            coverageDelta.insert(op.insert);
          }
        });
      }
      coverageDelta.insert(
        `Coverage Term: ${coverageTerm}${parseInt(coverageTerm).toString() === coverageTerm ? " months" : ""}\n`
      );
      return { ops: [...coverageDelta.ops] };
    } catch (e: any) {
      console.log(`Error during createProductCoverageDelta: ${e.message}`);
      return coverageDetails;
    }
  };

  const handelRunEngine = () => {
    Object.entries(productConfiguration).forEach(([field, value]) => {
      engine.addFact(field, value);
    });

    engine
      .run()
      .then(engineResults => {
        if (!engineResults.events.length) {
          // When events.length is 0, it means that the rules engine did not find any rules that matched the facts
          updateProductConfiguration({ isPricingValid: false });
          return;
        }

        engineResults.events.map(event => {
          if (event.type === "AFTERMARKET_PRODUCT_GET_DYNAMIC_PRODUCTS") {
            engine.removeRule("NTP Product Dynamic Coverage");
          } else if (event.type === "OVERRIDE_STRING_FACT_OPTIONS") {
            try {
              const overrides = event.params?.overrides;
              if (overrides) {
                overrides.forEach((override: any) => {
                  const factName = override.fact;
                  const factToCheckIndex = factsToCheck.findIndex(fact => fact.factKey === factName);
                  const factToCheck = factsToCheck[factToCheckIndex];
                  if (factToCheck?.type === "string") {
                    factsToCheck[factToCheckIndex].options = override.values;
                  }
                });
              }
            } catch (e) {
              console.log(`Error during OVERRIDE_STRING_FACT_OPTIONS: ${(e as any).message}`);
            }
          } else if (event.type === "AFTERMARKET_PRODUCT_QUALIFIED") {
            const typedEvent = event as AftermarketProductQualifiedEventOptions;

            const dealerCostValue = typedEvent?.params?.DEALER_COST ?? 0;
            const markupValue = typedEvent?.params?.MARKUP?.AMOUNT ?? 0;
            const rateFactorValue = (typedEvent?.params as any)?.RATE_FACTOR ?? 0;
            const retailCostValue = typedEvent?.params?.SUGGESTED_RETAIL_PRICE ?? 0;

            const dealerCost = rateFactorValue
              ? productsCalculationManager.calculateCostByRateFactor(rateFactorValue, equipmentCost)
              : dealerCostValue;

            const markupAmount = markupValue
              ? Number(markupValue)
              : productsCalculationManager.calculateMarkupFlat(retailCostValue, dealerCost);

            const retailCost = !rateFactorValue
              ? retailCostValue
              : productsCalculationManager.calculateRetailCostByFlatMarkup(dealerCost, markupAmount);

            let catalogDetails = { ...product.aftermarketProduct?.aftermarketProductCatalogDetails };

            if (productConfiguration.COVERAGE_TERM && catalogDetails) {
              try {
                catalogDetails.coverageDetails = createProductCoverageDelta(
                  catalogDetails.coverageDetails,
                  productConfiguration.COVERAGE_TERM
                );
              } catch (error) {
                logError(error);
              }
            }

            updateProductConfiguration({
              retailCost,
              cost: dealerCost,
              customPrice: false,
              isPricingValid: true,
              markup: { markup: markupAmount, type: MarkupType.Flat },
              rateFactor: rateFactorValue || productsCalculationManager.calculateRateFactor(dealerCost, equipmentCost),
              aftermarketProduct: {
                aftermarketProductCatalogDetails: catalogDetails,
              },
            });
          }
        });
      })
      .catch(console.log);
  };

  // TODO bad approach: should encapsulate this handler to context
  useEffect(() => {
    const isNecessaryRunEngine = !productConfiguration?.customPrice;

    if (isNecessaryRunEngine) handelRunEngine();
  }, [isNeedRunJsonEngine]);

  return (
    <ProposalCardContainer
      type={type}
      mode={mode}
      proposalProduct={product}
      factsSkipped={factsSkipped}
      prisingConfig={prisingConfig}
      equipmentCost={equipmentCost}
      logo={product.aftermarketProduct?.productLogoUrl}
      productConfiguration={productConfiguration}
      updateProductConfiguration={updateProductConfiguration}>
      {mode === ProposalProductCardModes.Desking && (
        <PricingResults isMatched={!!productConfiguration.isPricingValid} factsToCheck={factsToCheck} />
      )}

      <DynamicFields
        mode={mode}
        factsToCheck={factsToCheck}
        hideFieldsForRender={hideFieldsForRender}
        productConfiguration={productConfiguration}
        updateProductConfiguration={updateProductConfiguration}
      />
    </ProposalCardContainer>
  );
};
