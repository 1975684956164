import React, { useState } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionSummary, AccordionDetails, Box, Grid, Typography, Divider } from "@mui/material";
import { AftermarketProductVendorApiChannel, ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { Addon } from "./Addon";
import { ProductLogo } from "./ProductLogo";
import { formatCurrency } from "../../../../../utils";
import { ProductCatalogDetails } from "./ProductCatalogDetails";
import { ProposalProductsCardTypeChip } from "./ProposalProductsCardTypeChip";
import { useProductCatalogDetails } from "../../../hooks/useProductCatalogDetails";

interface Props {
  primaryColor: string;
  product: ProposalProduct;
}

export const MenuOptionConfirmationModalProductCard = ({ product, primaryColor }: Props) => {
  const classes = useStyles();

  const { productCategory, productCatalogDetailsKeysWithData } = useProductCatalogDetails(product);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const aftermarketProductCatalogDetails = product.aftermarketProduct?.aftermarketProductCatalogDetails!;

  const detailsKeysToShowOnSummary = ["paragraphDescription"];

  const detailsToShowOnSummary = productCatalogDetailsKeysWithData.filter(key =>
    detailsKeysToShowOnSummary.includes(key)
  );

  const detailsToShowInAccordion = productCatalogDetailsKeysWithData.filter(
    key => !detailsKeysToShowOnSummary.includes(key)
  );

  const productConfig = product.aftermarketProduct?.config.productConfig;

  return (
    <Box className={classes.productContainer}>
      <Grid container justifyContent="space-between" alignItems="center" marginBottom={1}>
        <ProductLogo product={product} />

        {productCategory && <ProposalProductsCardTypeChip productCategory={productCategory} />}
      </Grid>

      {!!productCatalogDetailsKeysWithData.length ? (
        <Accordion expanded={isExpanded} className={classes.accordion} onChange={() => setIsExpanded(prev => !prev)}>
          <AccordionSummary className={classes.accordionSummary}>
            <Box>
              <Typography variant="subtitle1">{product.title}</Typography>

              {productConfig?.COVERAGETERM && (
                <Box className={classes.coverageTermContainer}>
                  <Typography>Coverage Term: {productConfig.COVERAGETERM}</Typography>{" "}
                </Box>
              )}

              {detailsToShowOnSummary.map(key => (
                <ProductCatalogDetails key={key} catalogDetails={aftermarketProductCatalogDetails[key]} />
              ))}
            </Box>

            <Box display="flex" alignItems="center">
              <Typography className={classes.accordionSecondaryHeading} variant="button">
                {isExpanded ? "Less Details" : "More Details"}
              </Typography>

              <ExpandMoreIcon color="primary" />
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            {detailsToShowInAccordion.map(key => (
              <Box mt={0.5} ml={0.5} key={key}>
                <ProductCatalogDetails catalogDetails={aftermarketProductCatalogDetails[key]} />
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      ) : (
        <Typography variant="subtitle1">{product.title}</Typography>
      )}

      <Divider />

      {product.addons?.map((addon: any) => <Addon key={addon.title} addon={addon} primaryColor={primaryColor} />)}

      {product.retailCost > 0 && (
        <Typography variant="subtitle2" mt={1} align="center">
          {product.aftermarketProduct?.aftermarketVendorApiChannel === AftermarketProductVendorApiChannel.Gaig
            ? "Monthly Price"
            : "Retail Price"}
          : {formatCurrency(product.retailCost)}
        </Typography>
      )}
    </Box>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  accordion: {
    "&.MuiAccordion-root.MuiPaper-root": {
      backgroundColor: "transparent",
      boxShadow: "none",
      "&:before": {
        display: "none",
      },
    },
  },
  accordionSummary: {
    "&.MuiAccordionSummary-root.MuiButtonBase-root": {
      padding: 0,
      marginBottom: 6,
      minHeight: "auto",
    },
    "&.MuiAccordionSummary-root .MuiAccordionSummary-content": {
      margin: 0,
      alignItems: "flex-start",
      display: "flex",
      justifyContent: " space-between",
    },
  },
  accordionSecondaryHeading: {
    "&.MuiTypography-root": {
      color: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
  },
  productContainer: {
    background: "#F5F5F5",
    margin: 4,
    padding: 4,
  },
  coverageTermContainer: {
    fontSize: 13,
    paddingLeft: 8,
    paddingBottom: 4,
  },
}));
