import React, { useCallback } from "react";
import { logError } from "utils/logger";
import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { useNotifications } from "modules/notification";
import { portalConfigurationsActions } from "redux/portalConfigurationReducer";
import { SAVE_PORTAL_CONFIGURATION } from "../api";
import { PortalConfigurationRenderProps } from "../types";
import { SavePortalConfigurationReponse, UpdatePortalConfigurationVariables } from "../api/types";
import { portalConfigurationSchema } from "../model/schemas";

interface Props {
  children: (options: PortalConfigurationRenderProps) => React.ReactNode;
}

export const PortalConfigContainer = ({ children }: Props) => {
  const { showNotification } = useNotifications();

  const dispatch = useDispatch();
  const vendorProfileId = useSelector<any, string>(state => state.vp.id);

  const [savePortalConfiguration, { loading }] = useMutation<
    SavePortalConfigurationReponse,
    UpdatePortalConfigurationVariables
  >(SAVE_PORTAL_CONFIGURATION);

  const onSaveConfiguration = useCallback(
    async (configToSave: UpdatePortalConfigurationVariables["input"]) => {
      const { success } = portalConfigurationSchema.safeParse(configToSave);

      if (!success) {
        showNotification("Not saved! Issue with data for save!", { type: "error" });
        return;
      }

      try {
        const { data } = await savePortalConfiguration({ variables: { input: configToSave } });

        configToSave.portalConfigurationId = data?.savePortalConfiguration;
        dispatch(portalConfigurationsActions.setConfigData([configToSave]));

        showNotification("Saved!");
      } catch (error) {
        showNotification("Error!", { type: "error" });
        logError(error);
      }
    },
    [savePortalConfiguration, dispatch, showNotification]
  );

  return <>{children({ loading, onSaveConfiguration, vendorProfileId })}</>;
};
