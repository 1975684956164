import React from "react";
import { useForm } from "react-hook-form";
import { Help } from "@mui/icons-material";
import { SwitchField } from "components/form";
import { Box, Button, Stack, Tooltip } from "@mui/material";
import { RecertificationForm } from "../types";

interface Props {
  isLoading: boolean;
  defaultValues: RecertificationForm;
  onConfirm: (configuration: RecertificationForm) => Promise<void>;
}

export const Recertification = ({ isLoading, defaultValues, onConfirm }: Props) => {
  const { control, handleSubmit } = useForm<RecertificationForm>({
    defaultValues,
  });

  return (
    <Box sx={{ display: "grid", gridTemplateRows: "1fr auto", gap: "1rem", height: "100%" }}>
      <Stack gap="0.5rem">
        <Stack direction="row" alignItems="center" justifyContent="space-between" gap="0.5rem">
          <SwitchField label="Allow Document Uploads" control={control} name="isDocumentTurnedOn" />

          <Tooltip title="Enable if your team permits verbal confirmation to recertify applications on behalf of applicants.">
            <Help color="info" />
          </Tooltip>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" gap="0.5rem">
          <SwitchField label="Allow Verbal Authorization" control={control} name="isVerbalAuthorizationTurnedOn" />

          <Tooltip title="Enable if your team permits verbal confirmation to recertify applications on behalf of applicants.">
            <Help color="info" />
          </Tooltip>
        </Stack>
      </Stack>

      <Box>
        <Button loading={isLoading} variant="contained" onClick={handleSubmit(onConfirm)}>
          Save
        </Button>
      </Box>
    </Box>
  );
};
