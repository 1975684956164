import { gql } from "@apollo/client";
export const GET_PROPOSAL_METRICS = gql`
  query ProposalMetrics($input: ProposalMetricsInput!) {
    proposalMetrics(input: $input) {
      accepted {
        markup {
          byUser
          byLocation
        }
        byUser {
          total
          fullName
        }
        byDate {
          period
          total
        }
        byWeek {
          period
          total
        }
        byMonth {
          period
          total
        }
      }
      created {
        markup {
          byUser
          byLocation
        }
        byUser {
          total
          fullName
        }
        byDate {
          period
          total
        }
        byWeek {
          period
          total
        }
        byMonth {
          period
          total
        }
      }
      sent {
        markup {
          byUser
          byLocation
        }
        byUser {
          total
          fullName
        }
        byDate {
          period
          total
        }
        byWeek {
          period
          total
        }
        byMonth {
          period
          total
        }
      }
    }
  }
`;
