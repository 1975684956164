import Avatar from "@mui/material/Avatar";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import { People } from "@mui/icons-material";
import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { AssignmentInd } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import PublishIcon from "@mui/icons-material/Publish";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Switch, Typography } from "@mui/material";
import documentIcon from "assets/img/icons/document.png";
import _ from "lodash";
import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "./CustomButtons/Button";
import { asyncForEach, isEmailValid, vendorContactRoleHumanReadable } from "../utils";
import Tile from "./Tile/Tile";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import gql from "graphql-tag";
import { defaultFont, grayColor, primaryColor } from "assets/jss/material-dashboard-pro-react.js";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ListIcon from "@mui/icons-material/List";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import { DropzoneDialog } from "mui-file-dropzone";
import Fab from "@mui/material/Fab";
import GetAppIcon from "@mui/icons-material/GetApp";
import Modal from "@mui/material/Modal";
import { documentsByKey, referenceTypes } from "../pages/Prequal/constants";
import { rejectHiddenDocumentTypeOptions } from "../services/pcwService";
import { TradeReferenceResults } from "../components/TasksCommon/components/TradeReferenceResults";
const config = require("../config");

const useStyles = makeStyles(theme => ({
  margin: {
    row: {
      height: "20px",
    },
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  rootInput: {
    "& > *": {
      margin: theme.spacing(1),
      width: "50ch",
    },
  },
  paper: {
    position: "absolute",
    minWidth: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
  surface: {
    position: "absolute",
    width: "80%",
    height: 839,
    display: "flex",
    "@media (max-width: 660px)": {
      width: "90%",
      minWidth: "0",
    },
  },
  formContainer: {
    padding: "20px 0",
    "@media (max-width: 660px)": {
      width: "100%",
      minWidth: "0",
      margin: "0 auto",
    },
  },
  attachmentSubtitle: {
    textDecoration: "underline",
  },
  attachmentSeparator: {
    margin: "10px auto",
  },
  formContainerGridContainer: {
    width: "100%",
    margin: 0,
    "& .MuiGrid-grid-xs-12": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "@media (max-width: 599px)": {
      width: "100%",
      margin: 0,
      "& .MuiGrid-grid-xs-12": {
        padding: 0,
      },
    },
  },
  closeButton: {
    position: "absolute",
    right: "20px",
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    zIndex: "19",
    cursor: "pointer",
    width: "30px",
    height: "30px",
  },
  previewDoc: {
    maxWidth: "60%",
  },
  subHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  taskTitle: {
    fontSize: "20px",
    fontWeight: "500",
  },
  taskAssigned: {
    marginTop: "0px",
    fontSize: "20px",
    fontWeight: "300",
  },
  regardingSelect: {
    paddingTop: "11px",
  },
  titleChat: {
    display: "flex",
    flexDirection: "column",
    textTransform: "uppercase",
    fontSize: "14px",
    fontWeight: "300",
  },
  titleChatText: {
    fontSize: "16px",
    fontWeight: "500",
  },
  notifyContainer: {
    display: "flex",
    gap: 8,
    padding: "16px",
    alignItems: "center",
  },
  notifyCheckboxesContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  chatCheckboxStyles: {
    color: "#0176FF !important",
    padding: "0px 5px 2px 9px",
  },
  priorityLabel: {
    display: "inline-block",
    margin: "10px 0 0 0",
  },
  smallIcon: {
    fontSize: "20px",
    margin: "15px",
  },
  assignedSelect: {
    margin: "0 0 17px 0",
    paddingTop: "11px",
    position: "relative",
    verticalAlign: "unset",
    "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
      color: grayColor[14],
    },
  },
  assignedSelectUnderline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: grayColor[4] + "!important",
      borderWidth: "1px !important",
    },
    "&:after": {
      borderColor: primaryColor[0],
    },
    "& + p": {
      fontWeight: "300",
    },
  },
  assingnedSelectLabel: {
    ...defaultFont,
    color: grayColor[3] + " !important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "1.42857",
    top: "10px",
    letterSpacing: "unset",
    "& + $underline": {
      marginTop: "0px",
    },
  },
  dateSelect: {
    marginTop: "11px",
    width: "100%",

    "& label": {
      color: "#000000",
      fontSize: 14,
      fontWeight: 400,
    },
  },
  textField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input": {
      padding: "4px 18px",
    },
  },
  dateField: {
    margin: "8px 0",
    width: "100%",
    height: 40,

    "& input": {
      padding: 12,
      fontSize: 14,
    },
    "& fieldset:disabled": {
      borderColor: "#f44336",
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 24px",
    },
    "& fieldset legend span": {
      fontSize: 12,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
      textTransform: "none",
      fontWeight: "normal",
    },
  },
}));
// const useUiStyles = makeStyles(styles);
const getModalStyle = () => {
  return {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
};
const graphqlErrorMessage = {
  duplicateMessage:
    'GraphQL error: duplicate key value violates unique constraint "document_vendor_opportunity_id_doc_name_key"',
};

const UPLOAD_FILE = gql`
  mutation ($input: UpsertDocumentInput!, $file: Upload) {
    upsertDocument(input: $input, file: $file)
  }
`;

const DEACTIVATE_DOC = gql`
  mutation ($id: ID!) {
    deactivateDocument(id: $id)
  }
`;

const GET_LENDER_PROFILE_CONTACTS = gql`
  query GetLenderProfileByDynamicsId($lenderProfileDynamicsId: ID!) {
    getLenderProfileByDynamicsId(lenderProfileDynamicsId: $lenderProfileDynamicsId) {
      lenderMatrixMatchingRules
      lenderProfileUsers {
        accessDealerLocationIds
        accessDealerUserIds
        lenderProfileId
        lenderProfileToUserId
        lenderUserProfileId
        receivesNotifications
        notificationContact
        userProfile {
          # account
          id
          accountId
          adminRole
          availability
          dynamicsContactId
          email
          firstName
          lastName
          fullName
          title
          status
          lastLogin
          phoneNumber
          mobilePhoneNumber
        }
      }
    }
  }
`;

const GET_CREDIT_SUBMISSIONS = gql`
  query CreditSubmissionsByDynamicsVOId($id: ID!, $display: String) {
    creditSubmissionsByDynamicsVOId(id: $id, display: $display) {
      creditSubmissionId
      creditSubId
      submissionDate
      decision
      lenderName
      amountRequested
      approvedAmount
      notes
      contingencies
      approvedRate
      creditSubStage
      apiApplicationId
      apiResult
      apiExpiredCredentials
      apiDetail
      lenderProfileId
      lenderChannelCode
      lenderTypeCode
      draft
      creditSubStage
      transactionSummary
      mappedValues
      source
      submittedBy
      lenderContactInfo {
        id
        email
        dynamicsContactId
        fullName
        title
        phoneNumber
        accountId
      }
    }
  }
`;

const DOCUMENT_E_SIGN_DYNAMICS_ACCOUNT_IDS = [
  "52f87212-4873-ee11-8171-066ef92796c9", // Test Vendor Auto
  "55b8872c-19b6-ee11-8171-066ef92796c9", // Test Vendor v4
  "bb71f539-02f0-eb11-813b-066ef92796c9", // Burris Equipment
  "de89d6a4-173e-ea11-8115-005056b05a0f", // TRNSACT Tractors
];

const BURRIS_SAMPLE_SALES_QUOTE_PDF_ID = "vMYw3NWPiC8WbpvjKpYH";

export default function DealerTaskForm(props) {
  const classes = useStyles();

  const {
    handleAddTask,
    handleSaveTask,
    refetchDocs,
    creditSubId,
    taskId, //NULL FOR NEW TASKS
    tasksData,
    vendorProfile,
    lenderProfileDynamicsId,
    documentsPortalConfiguration,
    documents,
  } = props;

  const account = useSelector(state => state.account);
  const userProfile = useSelector(state => state.userProfile);
  let relatedUsers = useSelector(state => state.relatedUsers);

  const lenderProfiles = useSelector(state => state.lp);
  const creditApp = useSelector(state => state.creditApp);
  const vo = useSelector(state => state.vo);

  const regexp = /^\$?([\d,.]*)?$/;

  const [modalStyle] = useState(getModalStyle);
  const [task, setTask] = useState();
  const [displayFeatureNotAvailable, setDisplayFeatureNotAvailable] = useState(false);
  const [enabledForSMS, setEnabledForSMS] = useState(null);
  const [assignedByAccount, setAssignedByAccount] = useState("");
  const [assignedByUser, setAssignedByUser] = useState("");
  const [assignedByUserProfileId, setAssignedByUserProfileId] = useState("");
  const [assignee, setAssignee] = useState("");
  const [assignedToAccount, setAssignedToAccount] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [priority, setPriority] = useState("medium");
  const [type, setType] = useState("info");
  const [regarding, setRegarding] = useState("");
  const [status, setStatus] = useState("open");
  const [isSystemTask, setIsSystemTask] = useState(false);
  const [assigneeList, setAssigneeList] = useState([]);
  const [createdDateTime, setCreatedDateTime] = useState("");
  const [vendorOpportunityId, setVendorOpportunityId] = useState(null);
  const [notifyVia, setNotifyVia] = useState("email");
  const [notifyByEmail, setNotifyByEmail] = useState(true);
  const [notifyByText, setNotifyByText] = useState(false);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [avatar, setAvatar] = useState("");
  const [lastReminderSent, setLastReminderSent] = useState("");
  const [nextReminder, setNextReminder] = useState("");
  const [taskItems, setTaskItems] = useState([]);
  const [assignedToUserProfileId, setAssignedToUserProfileId] = useState(null);
  const [assignedToOwnerPgId, setAssignedToOwnerPgId] = useState(null);
  const [openUploadZone, setOpenUploadZone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [taskAssignedToMe, setTaskAssignedToMe] = useState(false);
  const [assigneeIsApplicant, setAssigneeIsApplicant] = useState(false);
  const [assigneeIsTradeReference, setAssigneeIsTradeReference] = useState(false);
  const [assigneeIsInsuranceReference, setAssigneeIsInsuranceReference] = useState(false);
  const [creditSubmission, setCreditSubmission] = useState();
  const [completedDateTime, setCompletedDateTime] = useState(null);
  const [taskItemIndex, setTaskItemIndex] = useState(0);
  const [taskDueDateTime, setTaskDueDateTime] = useState(moment().add(7, "d").toISOString());
  const [taskNotificationContactIds, setTaskNotificationContactIds] = useState([]);
  const [notifyAssignorOnReminder, setNotifyAssignorOnReminder] = useState(false);
  const [fieldErros, setFieldErros] = useState({
    email: false,
  });
  const extendedNotificationOptions = [
    { value: "sms", text: "SMS" },
    { value: "both", text: "Email & SMS" },
  ];

  const {
    loading: lpContactsLoading,
    data: lpContactsData,
    refetch,
  } = useQuery(GET_LENDER_PROFILE_CONTACTS, {
    variables: {
      lenderProfileDynamicsId: lenderProfileDynamicsId || "",
      fetchPolicy: "no-cache",
    },
  });

  const { data: creditSubmissionsData } = useQuery(GET_CREDIT_SUBMISSIONS, {
    variables: {
      id: vo.dynamicsVendorOpportunityId,
    },
  });

  const [fileInput] = useMutation(UPLOAD_FILE, {
    context: { authRequired: true },
  });

  const documentTypeOptionsForDealerAssignment = [
    documentsByKey.quote,
    documentsByKey.invoice,
    documentsByKey.buyers_order,
    documentsByKey.insurance_certificate,
    documentsByKey.driver_license,
    documentsByKey.bank_statements,
    documentsByKey.tax_returns,
    documentsByKey.other,
  ];
  const documentTypeOptionsForLenderAssignment = [
    documentsByKey.quote,
    documentsByKey.invoice,
    documentsByKey.buyers_order,
    documentsByKey.decision_letter,
    documentsByKey.insurance_certificate,
    documentsByKey.driver_license,
    documentsByKey.bank_statements,
    documentsByKey.tax_returns,
    documentsByKey.other,
  ];

  const documentTypeOptionsForApplicants = [
    documentsByKey.driver_license,
    documentsByKey.bank_statements,
    documentsByKey.tax_returns,
    documentsByKey.other,
  ];

  const isDocumentESignTaskAvailable = _.includes(DOCUMENT_E_SIGN_DYNAMICS_ACCOUNT_IDS, account.dynamicsAccountId);
  const documentsForESign = documents?.filter(d => d.docType === documentsByKey.quote.value);

  //Validations before submit
  useEffect(() => {
    if (assigneeIsTradeReference) {
      const isValid = isEmailValid(assignee?.email);
      setSubmitEnabled(isValid);
      setFieldErros({ ...fieldErros, email: !isValid });
      return;
    }

    if (assigneeIsInsuranceReference) {
      const emailIsValid = isEmailValid(assignee?.email);
      const isCreditSubmissionSet = !!creditSubmission;
      const isValid = emailIsValid && isCreditSubmissionSet;
      setSubmitEnabled(isValid);
      setFieldErros({ ...fieldErros, email: !emailIsValid /* creditSubmission: !isCreditSubmissionSet */ });
      return;
    }

    setSubmitEnabled(true);
    if (_.size(taskItems) == 0) {
      setSubmitEnabled(false);
    }
    if (_.isEmpty(assignee)) {
      setSubmitEnabled(false);
    }
  }, [assignee, taskItems, creditSubmission]);

  const [deactivateDocRequest, { data: deactivateDocResponseData, loading: mutationDeactivateDoc }] = useMutation(
    DEACTIVATE_DOC,
    {
      context: { authRequired: true },
    }
  );

  useEffect(() => {
    if (_.get(vo, "potentialCustomer.name", false)) {
      setRegarding(vo.potentialCustomer.name);
    }
    if (_.get(vo, "vendorOpportunityId", false)) {
      setVendorOpportunityId(vo.vendorOpportunityId);
    }
    let availableLenderUserProfiles = [];
    let availableLenderAccounts = [];
    let accounts = [account];
    if (creditSubId) {
      if (lpContactsData?.getLenderProfileByDynamicsId?.lenderProfileUsers?.length > 0) {
        availableLenderUserProfiles = lpContactsData.getLenderProfileByDynamicsId.lenderProfileUsers.map(
          lpu => lpu.userProfile
        );

        availableLenderAccounts = [_.find(lenderProfiles, { lenderProfileId: lenderProfileDynamicsId })?.lenderAccount];
      } else {
        availableLenderUserProfiles = _.filter(lenderProfiles, profile => {
          if (profile.lenderUserProfile) {
            return profile;
          }
        }).map(item => item.lenderUserProfile);

        availableLenderAccounts = _.filter(lenderProfiles, profile => {
          if (profile.lenderAccount) {
            return profile;
          }
        }).map(item => item.lenderAccount);
      }
      accounts = [account, ...availableLenderAccounts];
    }

    //Add applicant

    const { firstName, lastName, email, businessName, phone } = creditApp;

    let allRecipients = _.uniqBy(
      [
        // {
        //   fullName: `${firstName} ${lastName}`,
        //   id: '',
        //   company: businessName,
        //   mobilePhoneNumber: phone ? phone : null,
        //   email,
        // },
        ...props.relatedUsers,
        ...availableLenderUserProfiles,
      ],
      "id"
    );

    if (creditSubId) {
      allRecipients = _.uniqBy([...props.relatedUsers, ...availableLenderUserProfiles], "id");
    }

    let assigneeListToSet = _.map(creditApp.creditApplicationOwner, owner => {
      const isPrimaryContact = owner.ownerPgId === creditApp.primaryContact.ownerPgId;
      return {
        ownerPgId: owner.ownerPgId,
        firstName: owner.firstName,
        fullName: `${owner.firstName} ${owner.lastName}`,
        lastName: owner.lastName,
        email: owner.email,
        //title: "Customer",
        company: "Customer",
        parenthesesText: isPrimaryContact ? "Primary Contact" : "Contact",
        isApplicant: true,
      };
    });
    const primaryContact = creditApp.primaryContact;
    if (primaryContact && !_.find(assigneeListToSet, { email: primaryContact.email })) {
      assigneeListToSet.push({
        ownerPgId: primaryContact.ownerPgId,
        firstName: primaryContact.firstName,
        fullName: `${primaryContact.firstName} ${primaryContact.lastName}`,
        lastName: primaryContact.lastName,
        email: primaryContact.email,
        company: "Customer",
        parenthesesText: "Primary Contact",
        isApplicant: true,
      });
    }
    assigneeListToSet.push(
      ...allRecipients.map(up => {
        const account = _.find(accounts, {
          id: up.accountId,
        });
        if (account) {
          if (up.vendorContactRole) {
            return {
              ...up,
              company: "Internal",
              parenthesesText: vendorContactRoleHumanReadable[up.vendorContactRole],
            };
          } else {
            return {
              ...up,
              company: account.name,
              parenthesesText: "Lender",
            };
          }
        }
      })
    );

    const tradeReferences = creditApp?.references?.filter(r => r.referenceType === referenceTypes.trade);
    if (tradeReferences?.length) {
      const tradeReferenceAsassignees = tradeReferences.map(reference => ({
        referenceId: reference.referenceId,
        company: "Trade Reference",
        email: reference.contactEmail,
        fullName: `${reference.companyName}`,
        parenthesesText: `${reference.contactName}${reference.contactEmail ? ` - ${reference.contactEmail}` : ``}`,
        isTradeReference: true,
      }));
      assigneeListToSet = [...tradeReferenceAsassignees, ...assigneeListToSet];
    }

    const insuranceReference = creditApp?.references?.find(r => r.referenceType === referenceTypes.insurance);
    const creditSubmissions = creditSubmissionsData?.creditSubmissionsByDynamicsVOId;
    if (insuranceReference && creditSubmissions?.length) {
      const insuranceReferenceAsassignee = {
        referenceId: insuranceReference.referenceId,
        company: "Insurance Verification",
        email: insuranceReference.contactEmail,
        fullName: `${insuranceReference.companyName}`,
        parenthesesText: `${insuranceReference.contactName}${insuranceReference.contactEmail ? ` - ${insuranceReference.contactEmail}` : ``}`,
        isInsuranceReference: true,
      };
      assigneeListToSet.unshift(insuranceReferenceAsassignee);
    }
    setAssigneeList(_.compact(assigneeListToSet));
  }, [relatedUsers, lenderProfiles, vo, lpContactsData, creditApp, creditSubmissionsData]);

  useEffect(() => {
    if (taskId !== null && !_.isEmpty(tasksData)) {
      const currentTask = tasksData.find(t => t.taskId === taskId);

      const {
        assignedByAccount,
        assignedByUser,
        assignedToAccount,
        createdBy,
        status,
        regarding,
        priority,
        createdDateTime,
        assignedToUserProfileId,
        assignedByUserProfileId,
        notifyByEmail,
        notifyByText,
        lastReminderSent,
        nextReminder,
        taskItems,
        vendorOpportunityId,
        dueDateTime,
        notificationContactIds,
        notifyAssignorOnReminder,
        ownerPgId,
        completedDateTime,
        reference,
      } = currentTask;
      setTask(currentTask);

      //Load task info into Form
      setAssignedByAccount(assignedByAccount);
      setAssignedByUser(assignedByUser);
      setAssignedToAccount(assignedToAccount);
      setIsSystemTask(assignedToAccount == "System");
      setAssignedByUserProfileId(assignedByUserProfileId);
      setAssignedToUserProfileId(assignedToUserProfileId);
      setAssignedToOwnerPgId(ownerPgId);
      setCreatedBy(createdBy);
      setStatus(status);
      setType(type);
      setPriority(priority);
      setCreatedDateTime(createdDateTime);
      setNotifyByEmail(notifyByEmail);
      setNotifyByText(notifyByText);
      setLastReminderSent(lastReminderSent);
      setNextReminder(nextReminder);
      setNotificationsEnabled(notifyByEmail || notifyByText);
      setRegarding(regarding);
      setAssigneeIsApplicant(!!ownerPgId);
      setVendorOpportunityId(vendorOpportunityId);
      setCompletedDateTime(completedDateTime);
      setTaskDueDateTime(dueDateTime);
      setTaskNotificationContactIds(notificationContactIds?.length > 0 ? [...notificationContactIds] : []);
      setNotifyAssignorOnReminder(notifyAssignorOnReminder);

      setAssigneeIsTradeReference(!!reference);
      if (taskItems) {
        setTaskItems(
          taskItems.map(taskItem => {
            taskItem.invoiceQuoteNumber = null;
            if (taskItem.doc !== null) {
              let doc = taskItem.doc;
              doc.file = null;
              doc.reader = null;
              taskItem.doc = doc;
            } else {
              taskItem.doc = { file: null, reader: null };
            }

            return taskItem;
          })
        );
      } else {
        setTaskItems([]);
      }

      setTaskAssignedToMe(assignedToUserProfileId == userProfile.id);
      //Fill task Info data
    }
  }, [taskId, tasksData]);

  useEffect(() => {
    if (!_.isEmpty(assigneeList)) {
      let assignee;
      let assignor;
      if (!taskId) {
        assignor = _.find(assigneeList, { id: userProfile.id });
        setAssignedByUser(userProfile.fullName);
        setAssignedByUserProfileId(userProfile.id);
        setAssignedByAccount(account.name);
        setAssignee(null);
        if (_.get(assignor, "mugshot", false)) {
          setAvatar(`https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${assignor.mugshot}`);
        }
      } else {
        assignee =
          _.find(assigneeList, { id: assignedToUserProfileId }) ||
          _.find(assigneeList, { ownerPgId: assignedToOwnerPgId }) ||
          _.find(assigneeList, { referenceId: task?.reference?.referenceId });
        assignor = _.find(assigneeList, { id: assignedByUserProfileId });
        setAssignee(assignee);

        if (_.get(assignee, "mobilePhoneNumber", false)) {
          setEnabledForSMS(true);
        } else {
          setEnabledForSMS(false);
        }
        if (_.get(assignor, "mugshot", false)) {
          setAvatar(`https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${assignor.mugshot}`);
        }
      }
    }
  }, [assignedByUserProfileId, assignedToUserProfileId, assignedToOwnerPgId, assigneeList, userProfile, account]);

  useEffect(() => {
    if (notifyByEmail) {
      setNotifyVia("email");
    }
    if (notifyByText) {
      setNotifyVia("sms");
    }
    if (notifyByEmail && notifyByText) {
      setNotifyVia("both");
    }
  }, [notifyByText, notifyByEmail]);

  const handleDeactivateDoc = async value => {
    try {
      await deactivateDocRequest({
        variables: {
          id: value,
        },
      });
      if (refetchDocs) {
        await refetchDocs();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleDownload = doc => {
    try {
      const link = document.createElement("a");
      link.href = doc.source;
      link.setAttribute("target", "_blank");
      link.setAttribute("download", doc.docName);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };
  const handleStatus = event => setStatus(event.target.value);
  const handlePriority = event => setPriority(event.target.value);
  const handleCreatedDateTime = event => setCreatedDateTime(event.target.value);
  const handleNotifyVia = event => {
    switch (event.target.value) {
      case "email":
        setNotifyByEmail(true);
        break;
      case "sms":
        setNotifyByText(true);
        break;
      case "both":
        setNotifyByEmail(true);
        setNotifyByText(true);
        break;
    }
    return setNotifyVia(event.target.value);
  };
  const handleCloseUploadZone = () => setOpenUploadZone(false);

  const [mutationError, setMutationError] = useState("");

  const handleAddDoc = index => {
    setTaskItemIndex(index);
    setOpenUploadZone(true);
  };
  const handleAddItems = () => {
    setTaskItems([
      ...taskItems,
      {
        type: "docs",
        taskAssigneeNotes: "",
        taskAssignorNotes: "",
        docType: assigneeIsApplicant ? "driver license" : "invoice",
        doc: {
          file: null,
          reader: null,
        },
      },
    ]);
  };

  const handleRemoveItems = () => {
    let previous = _.cloneDeep(taskItems);

    const removedItem = previous.pop();
    // if (_.get(removedItem, 'documentId', false)) {
    //   handleDeactivateDoc(removedDoc.documentId);
    // }

    setTaskItems(previous);
  };

  const handleChangeNotificationReps = val => {
    setTaskNotificationContactIds(val.map(obj => obj.id));
  };

  const handleSaveTaskItems = (index, key, item) => {
    let previous = _.cloneDeep(taskItems);

    if (key == "file") {
      index = taskItemIndex;
      previous[index]["doc"]["file"] = item;
      previous[index]["doc"]["reader"] = null;
      if (item.type.includes("image")) {
        let reader = new FileReader();
        reader.readAsDataURL(item);
        reader.onloadend = () => {
          previous[index]["doc"]["reader"] = reader.result;
          setTaskItems(previous);
        };
      } else {
        previous[index]["doc"]["reader"] = documentIcon;
        setTaskItems(previous);
      }
    } else {
      setTaskItems(prevTaskItems => {
        prevTaskItems[index] = {
          ...prevTaskItems[index],
          [key]: item,
        };
        return [...prevTaskItems];
      });
    }
  };
  const handleCloseFeatureNotAvailableModal = () => setDisplayFeatureNotAvailable(false);

  const handleSubmit = async () => {
    let payloadInput = {
      vendorOpportunityId: vendorOpportunityId,
      regarding: regarding,
      status: status,
      assignedByUserProfileId: userProfile.id,
      assignedByAccountId: account.id,
      priority: priority,
      createdBy: userProfile.fullName,
      assignedByUser: userProfile.fullName,
      assignedByAccount: account.name,
      notifyAssignorOnReminder,
      dueDateTime: taskDueDateTime,
      notificationContactIds: taskNotificationContactIds,
      notifyByEmail,
    };
    if (assigneeIsTradeReference) {
      const tradeReference = creditApp?.references?.find(r => r.referenceId === assignee.referenceId);
      payloadInput = {
        ...payloadInput,
        assignedToUser: tradeReference.contactName || tradeReference.companyName || "Reference",
        assignedToAccount: "Reference",
        referenceId: assignee.referenceId,
        assignedToEmails: [assignee.email],
      };
    } else if (assigneeIsInsuranceReference) {
      const insuranceBroker = creditApp?.references?.find(r => r.referenceType === referenceTypes.insurance);
      payloadInput = {
        ...payloadInput,
        creditApplicationId: creditApp.id,
        creditSubId: creditSubmission.creditSubId,
        assignedToAccountId: creditSubmission.lenderContactInfo?.[0]?.accountId,
        assignedToUser: insuranceBroker.contactName || insuranceBroker.companyName,
        assignedToEmails: [insuranceBroker.contactEmail],
        emailTemplateId: "d-da8bec3016f544efa0a78d6ae339ef37",
      };
    } else {
      payloadInput = {
        ...payloadInput,
        assignedToUser: assignee.fullName,
        assignedToUserProfileId: assignee.id,
        assignedToAccountId: account.id,
        assignedToAccount: "will_be_changed_by_backend",
        creditSubId: creditSubId ? creditSubId : null,
        notifyByText,
        assignedToApplicant: assignee.ownerPgId ? true : null,
        ownerPgId: assignee.ownerPgId,
      };
    }

    if (taskItems) {
      payloadInput.taskItems = taskItems.map(taskItem => {
        return {
          type: taskItem.type,
          docType: taskItem.docType,
          taskAssigneeNotes: taskItem.taskAssigneeNotes,
          taskAssignorNotes: taskItem.taskAssignorNotes,
          documentId: taskItem.documentId,
        };
      });
    }

    setLoading(true);
    let uploadedFiles = [];
    //Uploading documents
    if (taskId) {
      await asyncForEach(taskItems, async doc => {
        try {
          if (doc.type == "docs" && doc.doc.file) {
            const invoiceQuoteNumber = _.get(doc, "invoiceQuoteNumber", null);

            let docDescription = doc.docType;

            //compliance with semantics on Documents module
            if (doc.docType === "invoice" || doc.docType === "quote") {
              docDescription = `${doc.docType}#${invoiceQuoteNumber}`;
            }

            const input = {
              vendorOpportunityId: vendorOpportunityId,
              source: "will_be_changed_by_server",
              invoiceQuoteNumber: invoiceQuoteNumber,
              createdBy: "will_be_changed_by_server",
              docName: "will_be_changed_by_server",
              docDescription,
              docType: doc.docType,
              creditAppId: creditApp.id,
              createdBy: userProfile.fullName,
              docName: "will_be_changed_by_server",
            };

            const file = doc.doc.file;

            const responseFileUpload = await fileInput({
              variables: {
                input: input,
                file,
              },
            });

            if (_.get(responseFileUpload, "data.upsertDocument.document", false)) {
              uploadedFiles.push({
                taskItemId: doc.taskItemId,
                documentId: responseFileUpload.data.upsertDocument.document,
              });
            }
          }
        } catch (err) {
          console.log("------------------------------------");
          console.log(err);
          console.log("------------------------------------");
        }
      });
    }

    let newCompletedDateTime = null;
    if (status !== "completed" && completedDateTime === null) {
      newCompletedDateTime = new Date();
    } else if (status !== "completed") {
      newCompletedDateTime = null;
    }

    try {
      if (taskId) {
        await handleSaveTask({
          taskId,
          input: {
            taskId,
            status,
            priority,
            completedDateTime: newCompletedDateTime,
            assignedToUserProfileId: assignee.id,
            assignedByAccount: account.name,
            assignedToUser: assignee.fullName,
            notifyByEmail,
            notifyByText,
            dueDateTime: taskDueDateTime,
            notificationContactIds: taskNotificationContactIds,
            notifyAssignorOnReminder,
            taskItems: taskItems.map(taskItem => {
              const foundUploadedDocumentId = _.get(
                _.find(uploadedFiles, {
                  taskItemId: taskItem.taskItemId,
                }),
                "documentId",
                false
              );
              const documentId =
                foundUploadedDocumentId || _.get(taskItem, "doc.documentId", null) || taskItem.documentId;
              return {
                type: taskItem.type,
                docType: taskItem.docType,
                taskAssigneeNotes: taskItem.taskAssigneeNotes,
                taskAssignorNotes: taskItem.taskAssignorNotes,
                documentId,
              };
            }),
          },
        });
      } else {
        await handleAddTask(payloadInput);
      }
    } catch (err) {
      console.log(err);
    }

    if (refetchDocs) {
      await refetchDocs();
    }
    setLoading(false);
    props.handleClose();
  };

  const getDocumentTypeOptions = () => {
    let result;
    switch (true) {
      case assigneeIsApplicant:
        result = documentTypeOptionsForApplicants;
        break;
      case taskAssignedToMe:
        result = documentTypeOptionsForLenderAssignment;
        break;
      default:
        result = documentTypeOptionsForDealerAssignment;
    }
    // reject for current user role
    result = rejectHiddenDocumentTypeOptions(documentsPortalConfiguration, result, userProfile.vendorContactRole);
    if (assignee) {
      // reject for assignee user role
      result = rejectHiddenDocumentTypeOptions(documentsPortalConfiguration, result, assignee.vendorContactRole);
    }
    return result;
  };

  const documentTypeOptions = getDocumentTypeOptions();

  if (loading) {
    return (
      <div style={modalStyle} className={classes.surface}>
        <Tile
          title={taskId ? (taskAssignedToMe ? "Work Task" : "Edit Task") : "Add Task"}
          maxHeightLimitByScreen={true}
          titleIcon={<AssignmentInd />}>
          <div className={classes.formContainer}>
            <LinearProgress />
          </div>
        </Tile>
      </div>
    );
  }

  return (
    <div style={modalStyle} className={classes.surface}>
      <IconButton aria-label="close" className={classes.closeButton} onClick={props.handleClose} size="large">
        <CloseIcon />
      </IconButton>
      <Tile title={taskId ? "Edit Task" : "Add Task"} maxHeightLimitByScreen={true} titleIcon={<AssignmentInd />}>
        <div className={classes.formContainer}>
          {/* Form start */}
          <form noValidate autoComplete="off">
            <Grid container spacing={4} className={classes.formContainerGridContainer}>
              <Grid item xs={12} sm={12} md={12} lg={12} className={classes.subHeaderContainer}>
                <span className={classes.taskTitle}>Task Details</span>
                <div>
                  <Avatar alt="profilePicture" src={avatar} />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={10} lg={10}>
                {isSystemTask ? (
                  <h5 className={classes.taskAssigned}>Assigned by System: Trnsact</h5>
                ) : (
                  <h5 className={classes.taskAssigned}>
                    Assigned by: {assignedByUser} {assignedByAccount ? `(${assignedByAccount})` : ""}
                  </h5>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  label="Regarding"
                  id="regarding"
                  className={classes.textField}
                  variant="outlined"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.regardingSelect,
                  }}
                  disabled={assigneeIsTradeReference || assigneeIsInsuranceReference}
                  slotProps={{
                    htmlInput: {
                      type: "text",
                      onChange: e => {
                        setRegarding(e.target.value);
                      },
                      value: regarding,
                    },

                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    sx={{ width: "100% " }}
                    label="DUE DATE:"
                    value={taskDueDateTime ? moment(taskDueDateTime) : null}
                    onChange={val => setTaskDueDateTime(val.toISOString())}
                  />
                </LocalizationProvider>
              </Grid>

              <Modal
                open={displayFeatureNotAvailable}
                onClose={handleCloseFeatureNotAvailableModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                <div style={modalStyle} className={classes.paper}>
                  <h3 id="simple-modal-title">Whoops! This feature is not yet available</h3>
                  <p id="simple-modal-description">
                    We are working hard. Check back soon. You can still use this to track what you need from your
                    applicants.
                  </p>
                </div>
              </Modal>

              {assigneeIsTradeReference && status === "complete" && <TradeReferenceResults task={task} />}

              {isSystemTask ? null : (
                <>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControl variant="outlined" fullWidth style={{ margin: "8px 0" }}>
                      <InputLabel id={`status-select-label`}>Status</InputLabel>
                      <Select
                        id={"status-select"}
                        labelId={`status-select-label`}
                        value={status}
                        onChange={handleStatus}
                        variant="outlined">
                        <MenuItem value="open">Open</MenuItem>
                        <MenuItem value="complete">Complete</MenuItem>
                        <MenuItem value="in_progress">In Progress</MenuItem>
                        <MenuItem value="cancel">Cancel</MenuItem>
                        <MenuItem value="requested">Requested</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControl fullWidth style={{ margin: "8px 0" }}>
                      <InputLabel id={`priority-select-label`}>Priority</InputLabel>
                      <Select
                        labelId={`priority-select-label`}
                        value={priority}
                        onChange={handlePriority}
                        inputProps={{
                          name: "priority",
                          id: "priority",
                        }}
                        variant="outlined">
                        <MenuItem value={"high"}>High</MenuItem>
                        <MenuItem value={"medium"}>Medium</MenuItem>
                        <MenuItem value={"low"}>Low</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Autocomplete
                          value={assignee}
                          options={assigneeList}
                          groupBy={option => option.company}
                          disabled={task?.taskId}
                          onChange={(event, contact) => {
                            setAssigneeIsApplicant(false);
                            setAssigneeIsTradeReference(false);

                            if (_.get(contact, "isApplicant")) {
                              setAssigneeIsApplicant(true);
                            }
                            if (_.get(contact, "isTradeReference")) {
                              setAssigneeIsTradeReference(true);
                              setRegarding("Trade Reference");
                            }
                            if (_.get(contact, "isInsuranceReference")) {
                              setAssigneeIsInsuranceReference(true);
                              setRegarding("Insurance Verification");
                            }
                            if (_.get(contact, "mobilePhoneNumber", false)) {
                              setEnabledForSMS(true);
                            } else {
                              setEnabledForSMS(false);
                            }
                            setNotifyVia("email"); //needs to reset
                            setAssignee(contact);
                          }}
                          getOptionLabel={option =>
                            `${option?.fullName ? option?.fullName : ``}${
                              option.parenthesesText ? ` (${option.parenthesesText})` : ""
                            }`
                          }
                          style={{ height: 40 }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Assigned To"
                              variant="outlined"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              placeholder="Search"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {assignee && (assigneeIsTradeReference || assigneeIsInsuranceReference) && (
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        onChange={e => {
                          setAssignee(prev => ({ ...prev, email: e.target.value }));
                        }}
                        value={assignee.email ?? ""}
                        error={fieldErros.email}
                        helperText={fieldErros.email && "Please enter a valid email"}
                        disabled={task?.taskId}
                      />
                    </Grid>
                  )}

                  {assignee && assigneeIsInsuranceReference && (
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        style={{ margin: "8px 0" }}
                        error={fieldErros.creditSubmission}>
                        <InputLabel id={`credit-submission-label`}>Credit Submission</InputLabel>
                        <Select
                          labelId={`credit-submission-label`}
                          value={creditSubmission}
                          variant="outlined"
                          onChange={e => {
                            setCreditSubmission(e.target.value);
                          }}>
                          {creditSubmissionsData?.creditSubmissionsByDynamicsVOId?.map(cs => (
                            <MenuItem key={cs.creditSubmissionId} value={cs}>
                              {`${cs.lenderName}`}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}

                  {assignee && !assigneeIsTradeReference && !assigneeIsInsuranceReference && (
                    <Grid xs={12} sm={12} md={6} lg={6} className={classes.notifyContainer}>
                      <h5 className={classes.titleChatText}>Notify Via</h5>
                      <Grid className={classes.notifyCheckboxesContainer}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="medium"
                              name="notifyViaEmail"
                              className={classes.chatCheckboxStyles}
                              checked={notifyByEmail}
                              onChange={() => setNotifyByEmail(!notifyByEmail)}
                            />
                          }
                          label="Email"
                          size="small"
                        />
                        {enabledForSMS && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="medium"
                                disabled={!enabledForSMS}
                                name="notifyViaSMS"
                                className={classes.chatCheckboxStyles}
                                checked={notifyByText}
                                onChange={() => setNotifyByText(!notifyByText)}
                              />
                            }
                            size="small"
                            label="SMS"
                          />
                        )}
                      </Grid>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ margin: "24px 0" }}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <br />
                        <h4 style={{ display: "flex", alignItems: "center" }}>
                          <People color="action" size="small" style={{ marginRight: 4 }} />
                          Additional Notification Contacts
                        </h4>
                        <FormControl variant="outlined" fullWidth>
                          <Autocomplete
                            multiple
                            value={assigneeList.filter(val => taskNotificationContactIds.includes(val.id))}
                            options={assigneeList.filter(val => !taskNotificationContactIds.includes(val.id))}
                            onChange={(event, newValue) => {
                              handleChangeNotificationReps(newValue);
                            }}
                            isOptionEqualToValue={(option, value) => {
                              return option.id === value;
                            }}
                            getOptionLabel={option => {
                              return option.fullName;
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Contacts"
                                variant="outlined"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                placeholder=""
                              />
                            )}
                          />
                        </FormControl>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={notifyAssignorOnReminder}
                              onChange={() => setNotifyAssignorOnReminder(!notifyAssignorOnReminder)}
                              name="checkedB"
                              color="primary"
                            />
                          }
                          label="Notify Assignor & Additional Contacts on reminder"
                          style={{ color: "#000000", marginTop: 16 }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {assignee && !assigneeIsTradeReference && !assigneeIsInsuranceReference && (
                    <Grid item xs={12}>
                      <br />
                      <h4 style={{ display: "flex", alignItems: "center" }}>
                        <ListIcon color="action" size="small" style={{ marginRight: 4 }} />
                        Task Items
                      </h4>
                      {_.size(taskItems) ? (
                        <hr />
                      ) : (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Alert severity="info">Click on 'Add' to starting adding Task Items</Alert>
                        </Grid>
                      )}
                      {taskItems.map((taskItem, index) => {
                        return (
                          <div key={`taskItem.${index}.general`}>
                            <Grid container spacing={2}>
                              <DropzoneDialog
                                id={`dropZone.${index}`}
                                key={`dropZone[${index}]`}
                                open={openUploadZone}
                                onDrop={() => {}}
                                onSave={file => {
                                  handleSaveTaskItems(taskItemIndex, "file", file[0]);
                                  handleCloseUploadZone();
                                }}
                                onClose={() => {
                                  handleCloseUploadZone();
                                }}
                                filesLimit={1}
                                dropzoneText={"Drag or Click Here To Upload File"}
                                // acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                                showPreviews={false}
                                maxFileSize={25000000}
                              />
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <h5 className={classes.attachmentSubtitle}>Task Item #{index + 1}</h5>
                              </Grid>

                              <Grid item xs={12} sm={6} md={6} lg={6}>
                                <FormControl variant="outlined" fullWidth style={{ margin: "8px 0" }}>
                                  <InputLabel id={`type-select-label`}>Type</InputLabel>
                                  <Select
                                    labelId={`type-select-label`}
                                    key={`taskItems[${index}].type`}
                                    value={taskItem.type}
                                    variant="outlined"
                                    onChange={e => {
                                      handleSaveTaskItems(index, "type", e.target.value);
                                    }}
                                    inputProps={{
                                      name: `taskItems[${index}].type`,
                                      id: `taskItems[${index}].type`,
                                      disabled: taskAssignedToMe && taskId /*  || assigneeIsApplicant */,
                                    }}>
                                    <MenuItem value={"docs"}>Document Upload</MenuItem>
                                    {!assigneeIsApplicant && <MenuItem value={"info"}>Information Request</MenuItem>}
                                    {isDocumentESignTaskAvailable && assigneeIsApplicant && (
                                      <MenuItem value={"documentESign"}>Document E-Sign</MenuItem>
                                    )}
                                    {/* <MenuItem value={"action"}>Action Item</MenuItem> */}
                                  </Select>
                                </FormControl>
                              </Grid>
                              {taskItem.type == "docs" ? (
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                  <FormControl variant="outlined" fullWidth style={{ margin: "8px 0" }}>
                                    <InputLabel id={`docType-select-label`}>Document Type</InputLabel>
                                    <Select
                                      labelId={`docType-select-label`}
                                      value={taskItem.docType || ""}
                                      key={`taskItem[${index}].docType`}
                                      variant="outlined"
                                      onChange={e => {
                                        handleSaveTaskItems(index, "docType", e.target.value);
                                      }}
                                      inputProps={{
                                        name: `taskItems${index}].docType`,
                                        id: `taskItems${index}].docType`,
                                        disabled: taskAssignedToMe,
                                      }}>
                                      {documentTypeOptions.map((item, key) => {
                                        return (
                                          <MenuItem
                                            key={`taskItems${index}].docType[${item.value}]`}
                                            value={item.value}>
                                            {item.text}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              ) : null}

                              {taskItem.type == "documentESign" && (
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                  <FormControl variant="outlined" fullWidth style={{ margin: "8px 0" }}>
                                    <InputLabel id={`document-select-label`}>Document</InputLabel>
                                    <Select
                                      labelId={`document-select-label`}
                                      value={taskItem.documentId || ""}
                                      key={`taskItem[${index}].document`}
                                      variant="outlined"
                                      onChange={e => {
                                        const document = documentsForESign.find(d => d.documentId === e.target.value);
                                        handleSaveTaskItems(index, "documentId", document.documentId);
                                        handleSaveTaskItems(index, "docType", document.docType);
                                      }}
                                      inputProps={{
                                        name: `taskItems${index}].document`,
                                        id: `taskItems${index}].document`,
                                      }}>
                                      {documentsForESign.map(document => (
                                        <MenuItem
                                          key={`taskItems${index}].${document.documentId}`}
                                          value={document.documentId}>
                                          {`${document.docName} - ${document.docType}`}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              )}

                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                  className={classes.textField}
                                  variant="outlined"
                                  label={taskAssignedToMe ? "Notes from Assignor" : "Notes to Assignee"}
                                  id={`taskItem.${index}.taskAssignorNotes`}
                                  formControlProps={{ fullWidth: true }}
                                  slotProps={{
                                    htmlInput: {
                                      type: "text",
                                      disabled: taskAssignedToMe,
                                      onChange: e => {
                                        handleSaveTaskItems(index, "taskAssignorNotes", e.target.value);
                                      },
                                      value: taskItem.taskAssignorNotes || "",
                                    },

                                    inputLabel: {
                                      shrink: true,
                                    },
                                  }}
                                />
                              </Grid>
                              {taskAssignedToMe ? (
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    label="Response to Assignor"
                                    id={`taskItem.${index}.taskAssigneeNotes`}
                                    formControlProps={{ fullWidth: true }}
                                    slotProps={{
                                      htmlInput: {
                                        type: "text",
                                        disabled: !taskAssignedToMe,
                                        onChange: e => {
                                          handleSaveTaskItems(index, "taskAssigneeNotes", e.target.value);
                                        },
                                        value: taskItem.taskAssigneeNotes || "",
                                      },

                                      inputLabel: {
                                        shrink: true,
                                      },
                                    }}
                                  />
                                </Grid>
                              ) : null}

                              {taskItem.type == "docs" ? (
                                <>
                                  {/* SHOWN IN EDIT MODE */}
                                  {_.get(taskItem, "doc.documentId", false) ? (
                                    <>
                                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        {_.get(taskItem.doc, "docName", false) ? (
                                          <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            label="File Name"
                                            key={`docs[${index}].file`}
                                            formControlProps={{ fullWidth: true }}
                                            slotProps={{
                                              htmlInput: {
                                                type: "text",
                                                id: `docs[${index}].file`,
                                                value: taskItem.doc.docName || "",
                                              },

                                              inputLabel: {
                                                shrink: true,
                                              },
                                            }}
                                          />
                                        ) : null}
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        {_.get(taskItem.doc, "createdBy", false) ? (
                                          <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            label="Uploaded by"
                                            key={`docs[${index}].createdBy`}
                                            formControlProps={{ fullWidth: true }}
                                            slotProps={{
                                              htmlInput: {
                                                type: "text",
                                                id: `docs[${index}].createdBy`,
                                                value: taskItem.doc.createdBy || "",
                                              },

                                              inputLabel: {
                                                shrink: true,
                                              },
                                            }}
                                          />
                                        ) : null}
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                        {_.get(taskItem.doc, "source", false) ? (
                                          <Tooltip
                                            id="tooltip-top-start"
                                            title="Click to Download Document"
                                            placement="top">
                                            <Fab
                                              color="primary"
                                              size="small"
                                              onClick={() => handleDownload(taskItem.doc)}
                                              aria-label="download">
                                              <GetAppIcon />
                                            </Fab>
                                          </Tooltip>
                                        ) : null}
                                      </Grid>
                                    </>
                                  ) : (
                                    <>
                                      {/* SHOWN IN EDIT MODE */}
                                      {taskAssignedToMe ? (
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                          <Button
                                            onClick={() => {
                                              handleAddDoc(index);
                                            }}
                                            color="dribbble"
                                            size={"sm"}>
                                            <CloudUploadIcon size="sm" />
                                            Upload Document
                                          </Button>
                                        </Grid>
                                      ) : null}
                                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        {_.get(taskItem.doc, "file.name", false) ? (
                                          <TextField
                                            className={classes.textField}
                                            variant="outlined"
                                            label="File Name"
                                            key={`taskItem.doc[${index}].file`}
                                            formControlProps={{ fullWidth: true }}
                                            slotProps={{
                                              htmlInput: {
                                                type: "text",
                                                id: `taskItem.doc[${index}].file`,
                                                value: taskItem.doc.file.name || "",
                                              },

                                              inputLabel: {
                                                shrink: true,
                                              },
                                            }}
                                          />
                                        ) : null}
                                      </Grid>

                                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        {_.get(taskItem.doc, "reader", false) ? (
                                          <img src={taskItem.doc.reader} className={classes.previewDoc} />
                                        ) : null}
                                      </Grid>
                                    </>
                                  )}
                                </>
                              ) : null}
                            </Grid>
                          </div>
                        );
                      })}

                      {!taskAssignedToMe && (
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <br />
                            <br />
                            <br />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Grid container justifyContent="flex-start">
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Button
                                  disabled={_.isEmpty(assignee)}
                                  onClick={handleAddItems}
                                  color="primary"
                                  size={"sm"}>
                                  <AddCircleIcon />
                                  Add
                                </Button>
                                {_.size(taskItems) ? (
                                  <Button onClick={handleRemoveItems} color="primary" size={"sm"}>
                                    <RemoveCircleIcon /> Remove
                                  </Button>
                                ) : null}
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  )}
                </>
              )}

              <br />
              <br />
            </Grid>
          </form>
          {isSystemTask ? null : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "40px",
              }}>
              <Button onClick={handleSubmit} color="success" disabled={!submitEnabled} startIcon={<PublishIcon />}>
                Save
              </Button>
            </div>
          )}
          {/* {taskId ? (
            <ConversationsHistory
              conversations={props.conversations || []}
              refetch={props.refetchConversations}
              loading={props.conversationsLoading}
              voId={vo.vendorOpportunityId}
              relatedTaskId={taskId}
              tasks={true}
              voPotentialCustomerName={vo.potentialCustomer?.name || ""}
              handleConversationCreation={props.handleConversationCreation}
            />
          ) : null} */}
        </div>
      </Tile>
    </div>
  );
}
