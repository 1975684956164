import React from "react";
import { Chip, Stack } from "@mui/material";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";

interface Props {
  products: ProposalProduct[];
  onRemove: (productId: string) => void;
}

export const SelectedProductsList = ({ products, onRemove }: Props) => {
  if (!products.length) return null;

  return (
    <Stack direction="row" gap={"0.5rem"} flexWrap="wrap" width="100%">
      {products.map(product => (
        <Chip
          size="small"
          label={product.title}
          key={product.proposalProductId}
          onDelete={() => {
            onRemove(product.proposalProductId);
          }}
        />
      ))}
    </Stack>
  );
};
