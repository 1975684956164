import { Payment } from "@trnsact/trnsact-shared-types";

export enum ProposalsViewTypes {
  All = "all",
  My = "my",
  Dashboard = "dashboard",
}

export interface CellDefaultProps<TableData = any> {
  proposal: TableData;
}

export interface DetailsCellDefaultProps {
  details: any;
}

export interface CellArgs<TableData = any> {
  original: TableData;
}

export interface ColumnFilter {
  value: string[];
  id: string;
}

export enum PaymentOptionsDetailsRenderTypes {
  WithDetermine = "withDetermine",
  WithoutDetermine = "withoutDetermine",
}

export interface PaymentOptionsDetailsRenderOptions {
  variant: PaymentOptionsDetailsRenderTypes;
  acceptedTableData: Payment[];
  declinedTableData: Payment[];
}

export enum AftermarketCatalogDetailsKeys {
  ProductSummary = "productSummary",
  ParagraphDescription = "paragraphDescription",
  CoverageDetails = "coverageDetails",
  EligibilityAndInclusions = "eligibilityAndInclusions",
  LimitationsOfLiability = "limitationsOfLiability",
  LegalDisclaimer = "legalDisclaimer",
}

export interface ContractDetailsVendorData {
  status: string;
  dataPayload: Record<string, any>;
  dataResponse: Record<string, any>;
  aftermarketVendorApiChannel: string;
}

export interface ContractDetails {
  contractStartDateTime: string;
  userProfileIdContractStartedBy: string;
  proposalContractAftermarketVendorData: ContractDetailsVendorData[];
}

export interface ProposalMenuOptionDetailsDialogData {
  menuOption: any;
}

export interface ProposalContractDetailsDialogData {
  proposalId: string;
}

export interface ProductDetailsMappedData {
  tableData: ProductDetailsTableData[];
  totals: { cost: number; markup: number; retailCost: number };
}

export interface ProductDetailsTableData {
  id: string;
  name: string;
  cost: number;
  addons: any[];
  markup: number;
  vendor: string;
  retailCost: number;
  markupType: string;
  productCategory: string;
}
