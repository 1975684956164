import { ProposalMenuOption } from "@trnsact/trnsact-shared-types";
import { MenuConstructorState } from "./types";
import { initialMenuOptionOnAddEdit, initialProductsInMenuConfiguration, initialProposalMenu } from "../constants";
import { collectFactsToCheckFromProduct } from "modules/desking/lib/collectFactsToCheckFromProduct";
import { collectFactsSkippedOnProduct } from "modules/desking/lib/collectFactsSkippedOnProduct";
import { FactToCheck, ProposalProductCardModes } from "modules/desking/types";
import { immutableUtils } from "utils/immutable";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { ActionTypes } from "global";
import { menuConstructorActions } from "./actions";

type MenuConstructorActions = ActionTypes<typeof menuConstructorActions>;

const initialState: MenuConstructorState = {
  proposalMenu: initialProposalMenu,
  proposalProducts: {
    products: [],
    productsConfiguration: {},
    productsConfigurationInMenu: { ...initialProductsInMenuConfiguration },
    productsFactsToCheck: {},
    productFactsSkipped: {},
  },
  selectedProposalsProducts: [],
  menuOptionToAddEdit: { ...initialMenuOptionOnAddEdit },
  selectedMenuOption: null,
  archivedMenuOptions: [],
  initialProposalMenu: { ...initialProposalMenu },
  partnerLinksById: {},
  isLoading: true,
  layout: {
    sections: {},
  },
};

function setProducts(state: MenuConstructorState, products: ProposalProduct[]): MenuConstructorState {
  const productFactDetails = products.reduce<{
    productFactsToCheck: Record<string, FactToCheck[]>;
    productFactsSkipped: Record<string, FactToCheck[]>;
  }>(
    (acc, product) => {
      acc.productFactsToCheck[product.proposalProductId] = collectFactsToCheckFromProduct(product);
      acc.productFactsSkipped[product.proposalProductId] = collectFactsSkippedOnProduct(
        product,
        ProposalProductCardModes.Constructor
      );
      return acc;
    },
    {
      productFactsToCheck: {},
      productFactsSkipped: {},
    }
  );

  return {
    ...state,
    proposalProducts: {
      ...state.proposalProducts,
      // @ts-expect-error: TODO: Update typing
      products,
      productsFactsToCheck:
        products?.length > 0
          ? products.reduce((acc: any, product: any) => {
              if (!acc[product.proposalProductId]) {
                acc[product.proposalProductId] = collectFactsToCheckFromProduct(
                  product,
                  ProposalProductCardModes.Constructor
                );
              }

              return acc;
            }, {})
          : [],
      productFactsSkipped: productFactDetails.productFactsSkipped,
      productsConfiguration:
        products?.length > 0
          ? products.reduce((acc: any, product: any) => {
              acc[product.proposalProductId] = {
                cost: product?.cost ?? 0,
                retailCost: product?.retailCost ?? 0,
                markup: product?.markup ?? { markup: 0, type: "FLAT" },
                ...(product?.aftermarketProduct?.config?.criteriaValues[0] ?? {}),
              };

              return acc;
            }, {})
          : [],
    },
  };
}

export const menuConstructorReducer = (state = initialState, action: MenuConstructorActions) => {
  switch (action.type) {
    case "MENU_CONSTRUCTOR_SET_PROPOSALS_MENU":
      return {
        ...state,
        proposalMenu: {
          ...action.payload,
          menuOptions: action.payload.menuOptions?.map((option: any) => ({
            ...option,
            products: option.products.toSorted((a: any, b: any) => a.ordinal - b.ordinal),
          })),
        },
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: action.payload.menuOptions?.reduce((menuAcc: any, option: any) => {
            menuAcc[option.name] = option.products.reduce((acc: any, product: any) => {
              acc[product.proposalProductId] = {
                cost: product?.cost ?? 0,
                retailCost: product?.retailCost ?? 0,
                markup: product?.markup ?? { markup: 0, type: "FLAT" },
                ...(product?.aftermarketProduct?.config?.criteriaValues[0] ?? {}),
              };

              return acc;
            }, {});

            return menuAcc;
          }, {}),
        },
      };

    case "MENU_CONSTRUCTOR_SET_MENU_GENERAL_FORM_VALUES":
      return {
        ...state,
        proposalMenu: { ...state.proposalMenu, ...action.payload },
      };

    case "MENU_CONSTRUCTOR_SET_PROPOSALS_PRODUCTS":
      return setProducts(state, action.payload);

    case "MENU_CONSTRUCTOR_SET_SELECTED_PROPOSALS_PRODUCTS":
      return {
        ...state,
        selectedProposalsProducts: action.payload,
      };

    case "MENU_CONSTRUCTOR_UNSELECT_PRODUCT":
      return {
        ...state,
        selectedProposalsProducts: state.selectedProposalsProducts.filter(
          ({ proposalProductId }) => proposalProductId !== action.payload.productId
        ),
      };

    case "MENU_CONSTRUCTOR_SET_MENU_OPTION_TO_ADD_EDIT":
      return {
        ...state,
        menuOptionToAddEdit: action.payload.currentMenuOption,
      };

    case "MENU_CONSTRUCTOR_SET_PARTNER_LINKS_BY_ID":
      return {
        ...state,
        partnerLinksById: action.payload,
      };

    case "MENU_CONSTRUCTOR_ADD_MENU_OPTION":
      return {
        ...state,
        proposalMenu: {
          ...state.proposalMenu,
          menuOptions: [...state.proposalMenu.menuOptions!, action.payload.menuOption],
        },
      };

    case "MENU_CONSTRUCTOR_EDIT_MENU_OPTION":
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: {
            [action.payload.menuOption.name as string]:
              state.proposalProducts.productsConfigurationInMenu[state.menuOptionToAddEdit.name!],
            ...Object.entries(state.proposalProducts.productsConfigurationInMenu).reduce(
              (acc: any, [optionName, optionConfig]) => {
                if (optionName === state.menuOptionToAddEdit.name) return acc;

                acc[optionName] = optionConfig;

                return acc;
              },
              {}
            ),
          },
        },
        proposalMenu: {
          ...state.proposalMenu,
          menuOptions: state.proposalMenu.menuOptions!.map((option: ProposalMenuOption) => {
            return option.ordinal ===
              action.payload.menuOption
                .ordinal /* option.proposalMenuOptionId === action.payload.menuOption.proposalMenuOptionId */
              ? action.payload.menuOption
              : option;
          }),
        },
      };

    case "MENU_CONSTRUCTOR_REMOVE_MENU_OPTION":
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: Object.entries(state.proposalProducts.productsConfigurationInMenu).reduce(
            (acc: any, [optionName, config]) => {
              if (optionName === action.payload.currentMenuOption.name) return acc;

              acc[optionName] = config;

              return acc;
            },
            {}
          ),
        },
        proposalMenu: {
          ...state.proposalMenu,
          menuOptions: state.proposalMenu.menuOptions!.filter((option: ProposalMenuOption) => {
            return option !== action.payload.currentMenuOption;
          }),
        },
        archivedMenuOptions: action.payload.currentMenuOption.proposalMenuOptionId
          ? [...state.archivedMenuOptions, { ...action.payload.currentMenuOption, archived: true }]
          : state.archivedMenuOptions,
      };

    case "MENU_CONSTRUCTOR_SELECT_MENU_OPTION":
      return {
        ...state,
        selectedMenuOption: action.payload.menuOption,
      };

    case "MENU_CONSTRUCTOR_ADD_MENU_OPTION_PRODUCT":
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: {
            ...state.proposalProducts.productsConfigurationInMenu,
            [action.payload.menuType]: {
              ...state.proposalProducts.productsConfigurationInMenu?.[action.payload.menuType],
              [action.payload.product.proposalProductId]:
                state.proposalProducts.productsConfiguration[action.payload.product.proposalProductId],
            },
          },
        },
        proposalMenu: {
          ...state.proposalMenu,
          menuOptions: state.proposalMenu.menuOptions!.map((option: ProposalMenuOption) => {
            if (option.name === action.payload.menuType) {
              return {
                ...option,
                products: [...option.products, action.payload.product],
              };
            }

            return option;
          }),
        },
      };

    case "MENU_CONSTRUCTOR_REMOVE_MENU_OPTION_PRODUCT":
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: {
            ...state.proposalProducts.productsConfigurationInMenu,
            [action.payload.menuType]: immutableUtils.removeKeyFromObj(
              state.proposalProducts.productsConfigurationInMenu[action.payload.menuType],
              action.payload.productId
            ),
          },
        },
        proposalMenu: {
          ...state.proposalMenu,
          menuOptions: state.proposalMenu.menuOptions!.map((option: ProposalMenuOption) => {
            if (option.name === action.payload.menuType) {
              return {
                ...option,
                products: option.products.filter(product => product?.proposalProductId !== action.payload.productId),
              };
            }

            return option;
          }),
        },
      };

    case "MENU_CONSTRUCTOR_UPDATE_PRODUCT_CONFIGURATION":
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfiguration: {
            ...state.proposalProducts.productsConfiguration,
            [action.payload.productId]: {
              ...(state.proposalProducts.productsConfiguration?.[action.payload.productId] ?? {}),
              ...action.payload.data,
            },
          },
        },
      };

    case "MENU_CONSTRUCTOR_SWITCH_PRODUCTS":
      return {
        ...state,
        proposalMenu: {
          ...state.proposalMenu,
          menuOptions: state.proposalMenu.menuOptions?.reduce((acc: any, option) => {
            if (option.name === action.payload.menuName) {
              acc.push({
                ...option,
                products: immutableUtils.swapItems(option.products, action.payload.from, action.payload.to),
              });
            } else acc.push(option);

            return acc;
          }, []),
        },
      };

    case "MENU_CONSTRUCTOR_ADD_ALL_PRODUCTS_TO_MENU":
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: Object.entries(state.proposalProducts.productsConfigurationInMenu).reduce<
            MenuConstructorState["proposalProducts"]["productsConfigurationInMenu"]
          >((configurationInMenu, [optionName, configurationByProductId]) => {
            configurationInMenu[optionName] = {
              ...configurationByProductId,
              ...state.selectedProposalsProducts.reduce<Record<string, any>>((acc, product) => {
                if (!configurationByProductId[product.proposalProductId]) {
                  acc[product.proposalProductId] =
                    state.proposalProducts.productsConfiguration[product.proposalProductId];
                }
                return acc;
              }, {}),
            };
            return configurationInMenu;
          }, {}),
        },
        proposalMenu: {
          ...state.proposalMenu,
          menuOptions: state.proposalMenu.menuOptions!.map(option => ({
            ...option,
            products: [
              ...option.products,
              ...state.selectedProposalsProducts.filter(
                ({ proposalProductId }) =>
                  !option.products.map(product => product?.proposalProductId).includes(proposalProductId)
              ),
            ],
          })),
        },
      };

    case "MENU_CONSTRUCTOR_UPDATE_SECTION_LAYOUT":
      return {
        ...state,
        layout: {
          sections: {
            ...state.layout.sections,
            [action.payload.section]: {
              [action.payload.key]: action.payload.value,
            },
          },
        },
      };

    case "MENU_CONSTRUCTOR_UPDATE_PRODUCT_CONFIGURATION_IN_MENU":
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          productsConfigurationInMenu: {
            ...state.proposalProducts.productsConfigurationInMenu,
            [action.payload.menuName]: {
              ...(state.proposalProducts.productsConfigurationInMenu?.[action.payload.menuName] ?? {}),
              [action.payload.productId]: {
                ...(state.proposalProducts.productsConfigurationInMenu?.[action.payload.menuName]?.[
                  action.payload.productId
                ] ?? {}),
                ...action.payload.data,
              },
            },
          },
        },
      };

    case "MENU_CONSTRUCTOR_SET_INITIAL_PROPOSALS_MENU":
      return {
        ...state,
        initialProposalMenu: {
          ...action.payload,
          menuOptions: action.payload.menuOptions?.map((option: any) => ({
            ...option,
            products: option.products.toSorted((a: any, b: any) => a.ordinal - b.ordinal),
          })),
        },
      };

    case "MENU_CONSTRUCTOR_SET_IS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };

    case "MENU_CONSTRUCTOR_CLEAR":
      return initialState;

    default:
      return state;
  }
};
