import React, { FC } from "react";
import { CardPriceSimple } from "./CardPriceSimple";
import { CardPriceWithBg } from "./CardPriceWithBg";
import { CommonMenuPriceValues } from "../../../types";

interface Props extends Omit<CommonMenuPriceValues, "rateFactor"> {
  type: "simple" | "withBg";
}

export const CardPriceBlockFactory = ({ type, ...other }: Props) => {
  const render: Record<"simple" | "withBg", FC<Omit<CommonMenuPriceValues, "rateFactor">>> = {
    simple: CardPriceSimple,
    withBg: CardPriceWithBg,
  };

  const Component = render[type];

  return <Component {...other} />;
};
