import gql from "graphql-tag";

export const GET_PROPOSAL_PRODUCTS = gql`
  query GetProductsForAftermarketConstructor($input: ProposalProductsInput) {
    proposalProducts(input: $input) {
      proposalProductId
      title
      cost
      markup {
        markup
        type
      }
      retailCost
      thirdPartyUrl
      createdDateTime
      modifiedDateTime
      aftermarketProduct {
        aftermarketProductId
        aftermarketVendorApiChannel
        criteria
        productCategory
        productDescriptionExternal
        productDescriptionInternal
        productName
        productType
        createdDateTime
        modifiedDateTime
        config {
          criteriaValues
        }
        aftermarketProductCatalogDetails {
          productSummary
          limitationsOfLiability
          eligibilityAndInclusions
          legalDisclaimer
          paragraphDescription
          coverageDetails
        }
      }
    }
  }
`;
