import React from "react";
import clsx from "clsx";
import { formatCurrency } from "utils";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { lightGreen, orange, teal } from "@mui/material/colors";
import { MarkupValues } from "./MarkupValues";
import { CommonMenuPriceValues } from "../../../types";
import { ProductPrice } from "./ProductPrice";

export const CardPriceSimple = ({ markup, retailCost, cost }: Omit<CommonMenuPriceValues, "rateFactor">) => {
  const classes = useStyles();

  return (
    <Box className={classes.cardPrice}>
      <Box className="priceBlock">
        <Box className={clsx("priceLabel", "cost")}>
          <Typography component="span" variant="subtitle2" color="textSecondary">
            Cost
          </Typography>
        </Box>

        <Typography component="span">
          <ProductPrice value={cost} />
        </Typography>
      </Box>

      <Box className="priceBlock">
        <Box className={clsx("priceLabel", "markup")}>
          <Typography component="span" variant="subtitle2" color="textSecondary">
            Markup
          </Typography>
        </Box>

        <Typography component="span">
          <MarkupValues markup={markup} />
        </Typography>
      </Box>

      <Box className="priceBlock">
        <Box className={clsx("priceLabel", "price")}>
          <Typography component="span" variant="subtitle2" color="textSecondary">
            Price
          </Typography>
        </Box>

        <Typography component="span">
          <ProductPrice value={retailCost} />
        </Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  cardPrice: {
    gap: "4px",
    display: "flex",
    justifyContent: "space-around",
    "& .priceBlock": {
      flex: 1,
      padding: "4px 0",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },

    "& .priceLabel": {
      width: "100%",
      display: "flex",
      padding: "4px 12px",
      alignItems: "center",
      borderRadius: "24px",
      flexDirection: "column",
      justifyContent: "center",
    },

    "& .cost": {
      backgroundColor: orange["50"],
    },

    "& .markup": {
      backgroundColor: teal["50"],
    },

    "& .price": {
      backgroundColor: lightGreen["50"],
    },
  },
});
