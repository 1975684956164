import React from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { CardContainer } from "components/shared/CardContainer/CardContainer";
import { portalConfigurationsSelectors } from "redux/portalConfigurationReducer";
import { PortalConfiguration, PortalConfigurationType } from "@trnsact/trnsact-shared-types";
import { RecertificationForm } from "../types";
import { Recertification } from "./Recertification";
import { usePortalConfigurationChecks } from "../../hooks";
import { PortalConfigurationRenderProps } from "../../types";
import { PortalConfigurationRolesSettingsKeys } from "../../../../types";
import { getPortalConfigurationDefaultConfig } from "../../lib/getPortalConfigurationDefaultConfig";

interface Props extends PortalConfigurationRenderProps {
  type: PortalConfigurationType;
}

export const RecertificationManagement = ({ type, loading, onSaveConfiguration, vendorProfileId }: Props) => {
  const { checkPortalConfigEntryForCurrentUserRole } = usePortalConfigurationChecks();

  const isHidden = checkPortalConfigEntryForCurrentUserRole(
    PortalConfigurationType.PortalSetup,
    PortalConfigurationRolesSettingsKeys.RecertificationManagementHidden
  );

  const { portalConfig, jsonConfig } = useSelector((state: any) => {
    const defaultConfig = getPortalConfigurationDefaultConfig(vendorProfileId, type);

    const portalConfig = portalConfigurationsSelectors.configDataByType(state, type) ?? defaultConfig;
    const jsonConfig = portalConfig?.jsonDefinition?.config ?? defaultConfig.jsonDefinition.config;

    return { portalConfig, jsonConfig };
  });

  const handleSave = async (formData: RecertificationForm) => {
    const configToSave: PortalConfiguration = { ...portalConfig, jsonDefinition: { config: formData } };
    await onSaveConfiguration(configToSave);
  };

  if (isHidden) return null;

  return (
    <Grid item sm={12} md={6}>
      <CardContainer
        title="Credit Application: Recertification Management"
        subTitle="Configure which recertification methods are available to your team.">
        <Recertification isLoading={loading} onConfirm={handleSave} defaultValues={jsonConfig} />
      </CardContainer>
    </Grid>
  );
};
