import React from "react";
import { CommonMenuPriceValues, MarkupType } from "../../../types";
import { ProductPrice } from "./ProductPrice";

export const MarkupValues = ({ markup }: Pick<CommonMenuPriceValues, "markup">) => {
  const formatters: Record<MarkupType, (value: number) => React.ReactNode> = {
    [MarkupType.Flat]: (value: number) => <ProductPrice value={value} />,
    [MarkupType.Percentage]: (value: number) => <ProductPrice value={value} formatter={() => `${value}%`} />,
  };

  if (!markup || !markup?.type) return "—";

  return formatters[markup.type](markup?.markup ?? 0);
};
