import React from "react";
import { Rate } from "@trnsact/ntp-sdk";
import { Theme } from "@mui/material/styles";
import { Box, Checkbox, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { lossCodesFormatter } from "../../../../../lib";
import { FormattedLossCode } from "modules/desking/lib/lossCodesFormatter";
import { ProductPrice } from "../../../cardPriceBlock/ProductPrice";

interface Props {
  addons: any[];
  addonsOptions: Rate["terms"][number];
  handleCheckboxChange: (lossCode: FormattedLossCode[]) => void;
}

export const Addons = ({ addonsOptions, handleCheckboxChange, addons }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.addonsCardContainer}>
      {addonsOptions.components.map(component => (
        <Box key={component.description} className={classes.addonsCard}>
          <Typography component="span" variant="subtitle2">
            {component.description}
          </Typography>

          <Box className="list">
            {component.lossCodeInputCriteria === "single-select" ? (
              <RadioGroup
                value={
                  addons?.find((addon: any) =>
                    component.lossCodes.some(lossCode => addon?.thirdPartyId === lossCode?.code)
                  )?.thirdPartyId || "None"
                }
                onChange={e => {
                  handleCheckboxChange(
                    e.target.value === "None"
                      ? component.lossCodes.map(lossCode => lossCodesFormatter(component, lossCode, false))
                      : component.lossCodes.map(lossCode =>
                          lossCodesFormatter(component, lossCode, e.target.value === lossCode.code)
                        )
                  );
                }}>
                <FormControlLabel
                  value="None"
                  label="None"
                  key={component.description}
                  control={<Radio size="small" color="primary" />}
                />
                {component.lossCodes.map(lossCode => (
                  <FormControlLabel
                    key={lossCode.code}
                    value={lossCode.code}
                    control={<Radio size="small" color="primary" />}
                    label={<ProductPrice prefix={`${lossCode.description}: `} value={lossCode.dealerCost} />}
                  />
                ))}
              </RadioGroup>
            ) : (
              component.lossCodes.map(lossCode => {
                const checked = !!addons?.find((addon: any) => addon?.thirdPartyId === lossCode?.code);
                return (
                  <FormControlLabel
                    key={lossCode.code}
                    label={<ProductPrice prefix={`${lossCode.description}: `} value={lossCode.dealerCost} />}
                    control={
                      <Checkbox
                        size="small"
                        disableRipple
                        color="primary"
                        checked={checked}
                        onChange={e =>
                          handleCheckboxChange([lossCodesFormatter(component, lossCode, e.target.checked)])
                        }
                      />
                    }
                  />
                );
              })
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const useStyles = makeStyles<Theme>(({ palette }) => ({
  addonsCardContainer: {
    gap: "8px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  addonsCard: {
    gap: "8px",
    display: "flex",
    padding: "12px",
    flexDirection: "column",
    backgroundColor: palette.common.white,

    "& .list": {
      display: "flex",
      flexDirection: "column",
    },
  },
}));
